import React from "react";
import { Switch } from 'react-router-dom';
import { errorLayout } from '../../layouts/ErrorLayout';
import PublicLayout from '../../layouts/PublicLayout/PublicLayout';
import AppRoute from '../AppRoute';
import Loader from '../../helper/loaders';
import { dashboardLayout } from "../../layouts/DashboardLayout";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

const Login = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Signin/Signin')
);

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/not-found-page.component')
);

const VerifyAccount = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */  '../../containers/Signin/VerifyAccount')
);

const ForgotPassword = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Password/ForgotPassword')
);

const ResetPassword = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Password/ResetPassword')
);

const SiteSetting = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/SiteSetting/SiteSetting')
);

const ManageUsers = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Users/ManageUsers')
);

const CreateUser = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Users/CreateUser')
);

const EditUser = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Users/EditUser')
);

const Profile = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/Profile/Profile')
);

const AuthComponent = (props) => {
    let { match } = props;

    return (
        <React.Fragment>
            <Switch>

                <AppRoute
                    path={`${match.path}/login:sessexpire?`}
                    component={Login}
                    layout={PublicLayout}
                    type='public'
                    title='Login'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/forgot-password`}
                    component={ForgotPassword}
                    layout={PublicLayout}
                    type='public'
                    title='Forgot Password'
                />

                <AppRoute
                    exact={true}
                    // path={`${match.path}/reset-password`}
                    path={`${match.path}/reset-password/:email/:token`}
                    component={ResetPassword}
                    layout={PublicLayout}
                    type='public'
                    title='Reset Password'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/two-factor-authentication`}
                    component={VerifyAccount}
                    layout={AuthLayout}
                    type='public'
                    title='Verify Account'
                />

                <AppRoute
                    exact={true}
                    path={`${match.path}/site-setting`}
                    component={SiteSetting}
                    layout={AuthLayout}
                    type='public'
                    title='Site Setting'
                />

                <AppRoute
                    path={`${match.path}/master/manage-users`}
                    component={ManageUsers}
                    layout={dashboardLayout}
                    type='public'
                    title='Manage Users'
                />

                <AppRoute
                    path={`${match.path}/master/create-user`}
                    component={CreateUser}
                    layout={dashboardLayout}
                    type='public'
                    title='Create User'
                />

                <AppRoute
                    path={`${match.path}/master/edit-user/:id`}
                    component={EditUser}
                    layout={dashboardLayout}
                    type='public'
                    title='Edit User'
                />
                
                <AppRoute
                    path={`${match.path}/profile`}
                    component={Profile}
                    layout={dashboardLayout}
                    type='public'
                    title='Profile'
                />

                <AppRoute
                    path={`${match.path}/*`}
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />

            </Switch>
        </React.Fragment>
    )
};

export default AuthComponent;