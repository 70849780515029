import React from "react";
import { Switch } from 'react-router-dom';
import AppRoute from '../AppRoute';
import Loader from '../../helper/loaders';
import { dashboardLayout } from "../../layouts/DashboardLayout";
import { auth } from '../../utils/authentication/auth';

const AdditionalFields = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Fields/AdditionalFields")
);

const CreateNewField = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Fields/CreateNewField")
);

const EditField = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Fields/EditField")
);

const Settings = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Setting")
);

const AllLeads = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Leads/AllLeads")
);

const KickboxLeads = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Leads/KickboxLeads")
);

const Dashboard = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Dashboard/Dashboard")
);

const ManageOffers = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Offers/ManageOffers")
);

const CreateOffers = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Offers/CreateOffers")
);

const LPOffers = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Offers/LPOffers")
);

const AllLps = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Lp/AllLps")
)

const CreateLP = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Lp/CreateLP")
);

const EditLp = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Lp/EditLp")
);

const UpdateAllLp = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Lp/UpdateAllLp")
);

const EditOffer = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Offers/EditOffer")
);

const Migration = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Migration")
);

const MD5DeniedLeads = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Leads/MD5DeniedLeads")
);

const HidenOffers = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Offers/HiddenOffers")
);

const ThankYouCount = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Lp/ThankYouCount")
);

const DNCDeniedLeads = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Leads/DNCDenied")
);

const CollectiveReportDashboard = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/CollectiveReports/CollectiveReportDashboard")
);

const CoBrandDeniedLeads = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Leads/CoBrandDenied")
);

const FieldsRoute = (props) => {
    let { match } = props;

    return (
        <Switch>

            <AppRoute
                path={`${match.path}/fields/create-fields`}
                component={CreateNewField}
                layout={dashboardLayout}
                type='public'
                title='Create New Field'
            />

            <AppRoute
                path={`${match.path}/fields/edit/:id`}
                component={EditField}
                layout={dashboardLayout}
                type='public'
                title='Fields'
            />

            <AppRoute
                path={`${match.path}/fields`}
                component={AdditionalFields}
                layout={dashboardLayout}
                type='public'
                title='Fields'
            />
            <AppRoute
                path={`${match.path}/settings`}
                component={Settings}
                layout={dashboardLayout}
                type='public'
                title='Settings'
            />

            <AppRoute
                path={`${match.path}/leads`}
                component={AllLeads}
                layout={dashboardLayout}
                type='public'
                title='Leads'
            />

            <AppRoute
                path={`${match.path}/invalid-leads`}
                component={KickboxLeads}
                layout={dashboardLayout}
                type='public'
                title='Invalid Leads'
            />

            <AppRoute
                path={`${match.path}/md5-denied-leads`}
                component={MD5DeniedLeads}
                layout={dashboardLayout}
                type='public'
                title='MD5 Denied Leads'
            />

            <AppRoute
                path={`${match.path}/dashboard`}
                component={Dashboard}
                requireAuth={auth}
                layout={dashboardLayout}
                type='public'
                title='Dashboard Offers'
            />

            <AppRoute
                path={`${match.path}/offers/manage-offers`}
                component={ManageOffers}
                requireAuth={auth}
                layout={dashboardLayout}
                type='public'
                title='All Offers'
            />

            <AppRoute
                path={`${match.path}/offers/create`}
                component={CreateOffers}
                requireAuth={auth}
                layout={dashboardLayout}
                type='public'
                title='Create Offers'
            />

            <AppRoute
                path={`${match.path}/offers/lp-offers`}
                component={LPOffers}
                requireAuth={auth}
                layout={dashboardLayout}
                type='public'
                title='LP Offers'
            />

            <AppRoute
                path={`${match.path}/lp/all-lp`}
                component={AllLps}
                layout={dashboardLayout}
                type='public'
                title='All Lp'
            />

            <AppRoute
                path={`${match.path}/lp/create-lp`}
                component={CreateLP}
                layout={dashboardLayout}
                type='public'
                title='All Lp'
            />

            <AppRoute
                path={`${match.path}/lp/edit/:id`}
                component={EditLp}
                layout={dashboardLayout}
                type='public'
                title='Fields'
            />

            <AppRoute
                path={`${match.path}/lp/update-all-lp`}
                component={UpdateAllLp}
                layout={dashboardLayout}
                type='public'
                title='Update All Lp'
            />

            <AppRoute
                path={`${match.path}/offers/edit-offer/:id`}
                component={EditOffer}
                requireAuth={auth}
                layout={dashboardLayout}
                type='public'
                title='Edit Offer'
            />

            <AppRoute
                path={`${match.path}/migration`}
                component={Migration}
                layout={dashboardLayout}
                type='public'
                title='Migration'
            />
            
            <AppRoute
                path={`${match.path}/offers/hidden-offers`}
                component={HidenOffers}
                requireAuth={auth}
                layout={dashboardLayout}
                type='public'
                title='Hidden Offers'
            />

            <AppRoute
                path={`${match.path}/lp/thank-you-count`}
                component={ThankYouCount}
                layout={dashboardLayout}
                type='public'
                title='Thank You Count'
            />

            <AppRoute
                path={`${match.path}/dnc-denied-leads`}
                component={DNCDeniedLeads}
                layout={dashboardLayout}
                type='public'
                title='MD5 Denied Leads'
            />

            <AppRoute
                path={`${match.path}/cobrand-denied-leads`}
                component={CoBrandDeniedLeads}
                layout={dashboardLayout}
                type='public'
                title='Co-Brand Denied Leads'
            />
            
            <AppRoute
                path={`${match.path}/collective-dashboard`}
                component={CollectiveReportDashboard}
                requireAuth={auth}
                layout={dashboardLayout}
                type='public'
                title='Dashboard Offers'
            />
            
        </Switch>
    )
};

export default FieldsRoute;