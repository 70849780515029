import React from 'react';
import { withStyles, Drawer, Hidden } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import SideNavigationMenu from '../../components/SideNavigations/SideNavigationsMenu';

const styles = (theme) =>({
    drawer: { background: '#498bf6', width: 70, borderRight: 'none', marginTop: 62, },
    drawerClasszindex:{zIndex:'1400 !important'},
})

const SideBar = (props) =>{
    const { classes } = props;
    return(
        <React.Fragment>
            <Hidden only='xs'>
                <Drawer classes={{ paperAnchorLeft: classes.drawer,  paper:classes.drawerClasszindex  }} variant="permanent"  >
                    <SideNavigationMenu 
                        pageUrl={props.location.pathname}
                    />
                </Drawer>
            </Hidden>
        </React.Fragment>
    )
};

export default withStyles(styles)(withRouter(SideBar));

