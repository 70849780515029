import React, { useState, useEffect } from 'react';
import AuthHeader from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { UserAccountContext } from '../../contextHooks';
import { ValidateSessionTokenAPI } from '../../helper/services/API/Users';
import { UserLogout } from '../../helper/commonFunction';
import Snackbar from '../../components/Snackbar/Snackbar';
import Loader from '../../helper/loaders/ComponentLoader';

const AuthLayout = (props) => {

    const [userAccount, setUserAccount] = useState({});
    const [snackbarState, setSnackbarState] = useState({
        messageInfo: {
            open: false,
            message: null,
            variant: 'success'
        }
    });

    useEffect(() => {
        localStorage.getItem("id")
        ValidateSessionTokenAPI().then((res) => {
            if (res.success && res.data) {
                setUserAccount(res.data);
            }
            else {
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: 'Session Expired! Logout Successfully',
                        variant: 'success'
                    }
                });
                localStorage.removeItem("id");
                localStorage.removeItem("default_site_details");
                localStorage.removeItem("phone");
                localStorage.removeItem("site_details");
                localStorage.removeItem("selected_site_details");
                localStorage.removeItem("Child_systems");
                UserLogout(props.children.props);
            }
        }).catch((error) => {
            setSnackbarState({
                messageInfo: {
                    open: true,
                    message: 'Something went wrong!',
                    variant: 'error'
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.children.props]);

    return (
        <React.Fragment>
            {snackbarState.messageInfo.open && <Snackbar
                message={snackbarState.messageInfo.message}
                open={snackbarState.messageInfo.open}
                closeSnackBar={() => {
                    setSnackbarState({ messageInfo: { open: false, message: null, variant: 'success' } });
                }}
                variant={snackbarState.messageInfo.variant}
                autoHideDuration={5000}
            />}
            {
                Object.keys(userAccount).length > 0 ?
                    <React.Fragment>
                        <UserAccountContext.Provider value={userAccount}>
                            <AuthHeader
                                prps={props.children.props}
                            />
                            {props.children}
                        </UserAccountContext.Provider>
                        <Footer />
                    </React.Fragment>
                    : <Loader />
            }
        </React.Fragment>
    );
}

export default AuthLayout;