import React from "react";
import { Switch } from 'react-router-dom';
import AppRoute from '../AppRoute';
import Loader from '../../helper/loaders';
import { dashboardLayout } from "../../layouts/DashboardLayout";

const ManageOngage = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/MasterUnsubscribe/ManageOngageLists")
)

const ManageGHL = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/MasterUnsubscribe/ManageGHLAccounts")
);

const ManagePT = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/MasterUnsubscribe/ManagePinktelAccounts")
);

const AddEmailToQueue = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/MasterUnsubscribe/AddEmailToQueue")
);

const ManageEmailItems = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/MasterUnsubscribe/ManageEmails")
);

const EditEmailToQueue = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/MasterUnsubscribe/EditEmailToQueue")
);

const ManagePhone = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/MasterUnsubscribe/ManagePhone")
);

const EditPhone = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/MasterUnsubscribe/EditPhone")
);

const UnsubscribersComp = (props) => {
    let { match } = props;
    return (
        <React.Fragment>
            <Switch>
                <AppRoute
                    path={`${match.path}/manage-ongage`}
                    component={ManageOngage}
                    layout={dashboardLayout}
                    type='public'
                    title='Manage Ongage'
                />

                <AppRoute
                    path={`${match.path}/manage-ghl`}
                    component={ManageGHL}
                    layout={dashboardLayout}
                    type='public'
                    title='Manage GHL'
                />

                <AppRoute
                    path={`${match.path}/manage-pinktel`}
                    component={ManagePT}
                    layout={dashboardLayout}
                    type='public'
                    title='Manage PT'
                />

                <AppRoute
                    path={`${match.path}/add-email`}
                    component={AddEmailToQueue}
                    layout={dashboardLayout}
                    type='public'
                    title='Add Email To Queue'
                />

                <AppRoute
                    path={`${match.path}/manage-emails`}
                    component={ManageEmailItems}
                    layout={dashboardLayout}
                    type='public'
                    title='Manage Emails'
                />

                <AppRoute
                    path={`${match.path}/edit-email/:id`}
                    component={EditEmailToQueue}
                    layout={dashboardLayout}
                    type='public'
                    title='Fields'
                />

                <AppRoute
                    path={`${match.path}/manage-phone`}
                    component={ManagePhone}
                    layout={dashboardLayout}
                    type='public'
                    title='Manage Phone'
                />

                <AppRoute
                    path={`${match.path}/edit-phone/:id`}
                    component={EditPhone}
                    layout={dashboardLayout}
                    type='public'
                    title='Edit Phone'
                />

                {/* <AppRoute
                    path={`${match.path}/update-all-lp`}
                    component={UpdateAllLp}
                    layout={dashboardLayout}
                    type='public'
                    title='Update All Lp'
                /> */}
            </Switch>
        </React.Fragment>
    )
}

export default UnsubscribersComp;