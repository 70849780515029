import React from "react";
import { Switch } from 'react-router-dom';
import { errorLayout } from '../../layouts/ErrorLayout';
import AppRoute from '../AppRoute';
import Loader from '../../helper/loaders';
import { auth } from '../../utils/authentication/auth';
import { dashboardLayout } from "../../layouts/DashboardLayout";

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/not-found-page.component')
);

const CreateMasterOffer = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/MasterOffers/CreateMasterOffer')
);

const AllMasterOffers = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/MasterOffers/AllMasterOffers')
);

const EditMasterOffer = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/MasterOffers/EditMasterOffer')
);

const AddMasterRule = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/MasterMD5Rules/AddMasterRule')
);

const EditMasterMD5Rule = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/MasterMD5Rules/EditMasterMd5Rule')
);

const ManageMasterMd5Rules = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/MasterMD5Rules/ManageMasterMd5Rules')
);

const ManageDeletedMd5Rules = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/MasterMD5Rules/ManageDeletedMD5Rules')
);

const CreateReportGroup = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/CollectiveReports/CreateGroup')
);

const ManageReportGroups = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/CollectiveReports/ManageReportGroups')
);

const EditReportGroup = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/CollectiveReports/EditReportGroup')
);

const CollectiveDashboard = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/CollectiveReports/CollectiveReportDashboard')
);

const ManageSiteRank = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../../containers/CollectiveReports/ManageSiteRank')
);

const OngageReport = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/CollectiveReports/OngageReport")
);

const MasterHiddenOffers = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/MasterOffers/AllMasterHiddenOffers")
);

const MasterOffersRoute = (props) => {
    let { match } = props;

    return (
        <React.Fragment>
            <Switch>

                <AppRoute
                    path={`${match.path}/create-offer`}
                    component={CreateMasterOffer}
                    layout={dashboardLayout}
                    type='public'
                    title='Create Master Offer'
                />

                <AppRoute
                    path={`${match.path}/all-offers`}
                    component={AllMasterOffers}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='All Master Offers'
                />

                <AppRoute
                    path={`${match.path}/edit-offer/:id`}
                    component={EditMasterOffer}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='Edit Master Offer'
                />

                <AppRoute
                    path={`${match.path}/add-md5Rule`}
                    component={AddMasterRule}
                    layout={dashboardLayout}
                    type='public'
                    title='Create MD5 Rule'
                />

                <AppRoute
                    path={`${match.path}/edit-md5Rule/:id`}
                    component={EditMasterMD5Rule}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='Edit MD5 Rule'
                />

                <AppRoute
                    path={`${match.path}/manage-md5Rules`}
                    component={ManageMasterMd5Rules}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='All MD5 Rules'
                />
                
                <AppRoute
                    path={`${match.path}/manage-hidden-md5Rules`}
                    component={ManageDeletedMd5Rules}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='All Hidden MD5 Rules'
                />
                
                <AppRoute
                    path={`${match.path}/reports`}
                    component={CollectiveDashboard}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='Master Reports'
                />

                <AppRoute
                    path={`${match.path}/manage-site-order`}
                    component={ManageSiteRank}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='Edit Group'
                />
                
                <AppRoute
                    path={`${match.path}/create-report-group`}
                    component={CreateReportGroup}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='Create Group'
                />

                <AppRoute
                    path={`${match.path}/manage-report-groups`}
                    component={ManageReportGroups}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='Manage Group'
                />

                <AppRoute
                    path={`${match.path}/edit-report-group/:id`}
                    component={EditReportGroup}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='Edit Group'
                />
                
                <AppRoute
                    path={`${match.path}/ongage-report`}
                    component={OngageReport}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='Ongage Report'
                />
                
                <AppRoute
                    path={`${match.path}/hidden-offers`}
                    component={MasterHiddenOffers}
                    requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='Ongage Report'
                />

                <AppRoute
                    path={`${match.path}/*`}
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />

            </Switch>
        </React.Fragment>
    )
};

export default MasterOffersRoute;