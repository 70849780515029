import Cookies from 'universal-cookie';

const cookies = new Cookies();

/* get cookie storage  */
export const getCookieStorage = (STORE_NAME) => {
  let dataStorage = cookies.get(STORE_NAME);

  if (dataStorage) {
    return typeof dataStorage === 'object' ? JSON.parse(dataStorage) : dataStorage;
  }
  return;
}

/* set cookie storage 
 Note:  if option path not specified then it default set to root path '/'   */
export const setCookieStorage = (STORE_NAME, data, option = { path: '/' }) => {

  if (data) {
    try {
      let value = typeof data === 'object' ? JSON.stringify(data) : data;
      cookies.set(STORE_NAME, value, option);
      return true;
    } catch (e) {
      return false;
    }
  } else {
    try {
      cookies.remove(STORE_NAME, option);
      return true;
    } catch (e) {
      return false;
    }
  }
}

/* get localstorage item */
export const getLocalStorage = (STORE_NAME) => {

  let dataStorage = localStorage.getItem(STORE_NAME);

  if (dataStorage) {
    return JSON.parse(dataStorage);
  }
  return;
}

/* set localstorage item */
export const setLocalStorage = (STORE_NAME, data) => {

  if (data) {
    try {
      localStorage.setItem(STORE_NAME, JSON.stringify(data));
      return true;
    } catch (e) {
      return false;
    }
  } else {
    try {
      localStorage.removeItem(STORE_NAME);
      return true;
    } catch (e) {
      return false;
    }
  }
}
const CookieLocalStorage = {
  getCookieStorage,
  setCookieStorage,
  getLocalStorage,
  setLocalStorage
};

export default CookieLocalStorage;