export const GetwayUrl = (siteId) => {
    const keys = Object.keys(process.env);
    let str = 'REACT_APP_API_GATEWAY_URL_' + siteId;
    let str2 = '';
    keys.forEach((key) => {
        if (key === str) {
            str2 = process.env[key];
        }
    });
    return str2;
};
let siteId = localStorage.getItem("id");

export const API_GATEWAY_URL = GetwayUrl(siteId);

const AuthUrl = process.env.REACT_APP_API_GATEWAY_URL_0;
// const AuthUrl = process.env.REACT_APP_API_GATEWAY_URL;
const OngageReportUrl = process.env.REACT_APP_API_ONGAGE_PROJECT_URI;

export const API_ENDPOINTS_URLS = {
    Login: AuthUrl + "users/login",
    Logout: AuthUrl + "users/logout",
    ForgotPassword: AuthUrl + "users/forgot_password",
    ValidateForgotLink: AuthUrl + "users/validate_forgot_link",
    ResetPassword: AuthUrl + "users/reset_password",
    ValidateSessionToken: AuthUrl + "users/validate_token",
    TwoFactorLogin: AuthUrl + "users/two_factor_login",
    VerifyTwoFactorLogin: AuthUrl + "users/verify_two_factor_code_login",
    FetchUserSiteSettings: API_GATEWAY_URL + "users/fetch_user_site_settings",
    SetDefaultSite: AuthUrl + "users/set_default_site",
    GetDefaultSiteSetting: AuthUrl + "users/fetch_default_site_setting",
    GetAllSiteSettingDetails: AuthUrl + "users/fetch_all_site_setting_details",
    CreateUser: AuthUrl + "admin/users/create_user",
    GetAllUsers: AuthUrl + "admin/users/All_users",
    GetSingleUserDetail: AuthUrl + "admin/users/user_details",
    UpdateUserDetails: AuthUrl + "admin/users/edit_user",
    DeleteUser: AuthUrl + "admin/users/delete_user",
    GetSettings: API_GATEWAY_URL + "admin/settings",
    UpdateSetting: API_GATEWAY_URL + "admin/setting/update_setting",
    TestEmailSetting: API_GATEWAY_URL + "admin/setting/test_email",
    createAdditionalFields: API_GATEWAY_URL + "admin/fields/create_additional_field",
    getAdditionalFields: API_GATEWAY_URL + "admin/fields/get_additional_fields",
    getAdditionalField: API_GATEWAY_URL + "admin/fields/get_additional_field",
    updateAdditionalFields: API_GATEWAY_URL + "admin/fields/update_additional_field",
    deleteField: API_GATEWAY_URL + "admin/fields/delete_field",
    invalidLeads: API_GATEWAY_URL + "admin/leads/invalid_leads",
    md5DeniedLeads: API_GATEWAY_URL + "admin/leads/md5_denied_leads",
    getMd5Report: API_GATEWAY_URL + "admin/leads/md5_denied_leads/get_report",
    donwloadMd5DeniedLeadsReport: API_GATEWAY_URL + "admin/leads/md5_denied_leads/download_report",
    listAllFields: API_GATEWAY_URL + "admin/fields/listAll",
    DNCDeniedLeads: API_GATEWAY_URL + "admin/leads/dnc_denied_leads",
    CoBrandDeniedLeads: API_GATEWAY_URL + "admin/leads/invalid_cobrand_leads",

    allLeads: API_GATEWAY_URL + "admin/leads",
    pushAllLeads: API_GATEWAY_URL + "admin/leads/purge",
    dashboardData: API_GATEWAY_URL + "admin/offers/dashboard",
    getReport: API_GATEWAY_URL + "admin/offers/get_report",
    leadCsvDownload: API_GATEWAY_URL + "admin/offers/download_lead_source",
    recentResultsClientResultsCsv: API_GATEWAY_URL + "admin/offers/download_csv",

    GetOffers: API_GATEWAY_URL + "admin/offers/get_all",
    DeletOffer: API_GATEWAY_URL + "admin/offers/delete_offer",
    UpdateOfferStatus: API_GATEWAY_URL + "admin/offers/edit_offers_status",
    CreateOffer: API_GATEWAY_URL + "admin/offers/create_offer",
    GenerateApiKey: API_GATEWAY_URL + "admin/offers/generate_api_key",
    GetLpOffers: API_GATEWAY_URL + "admin/offers/lp_offers",
    UpdateOffers: API_GATEWAY_URL + "admin/offers/edit_lp_offers",
    getAllOffers: API_GATEWAY_URL + "admin/offers/get_offer",
    updateOffer: API_GATEWAY_URL + "admin/offers/edit_offer",
    UpdateOfferLimit: API_GATEWAY_URL + "admin/offers/update_limit",

    CreateLp: API_GATEWAY_URL + "admin/offers/create_LP",
    DeleteLp: API_GATEWAY_URL + "admin/lp/delete_lp",
    GetAllLp: API_GATEWAY_URL + "admin/lp/all",
    UpdateThankyouUrls: API_GATEWAY_URL + "admin/lp/update_thankyou_urls",
    GetLp: API_GATEWAY_URL + "admin/get_lp",
    CreateLPCopy: API_GATEWAY_URL + "admin/lp/create_lp_copy",
    UpdateLp: API_GATEWAY_URL + "admin/offers/update_LP",
    ChangePassword: AuthUrl + "admin/users/change_password",

    getOffersImages: API_GATEWAY_URL + "internal/get_offer_image",
    setOffersImages: API_GATEWAY_URL + "internal/set_offer_image",
    setSettings: API_GATEWAY_URL + "internal/set_settings",
    leadsCount: API_GATEWAY_URL + "internal/total_count",
    getMigrationSettings: API_GATEWAY_URL + "internal/get_migration_setting",
    setOffersCount: API_GATEWAY_URL + "internal/set_offers_count",
    insertMongoLeads: API_GATEWAY_URL + "internal/insert_leads",
    leadsArchive: API_GATEWAY_URL + "internal/archive_data",
    offerLeadsArchive: API_GATEWAY_URL + "internal/offer_lead_archive_data",
    setThankyouUrls: API_GATEWAY_URL + "internal/thank_you_url",
    setThankyouUrlsCount: API_GATEWAY_URL + "internal/set_thank_you_url_count",
    changeOfferImagePath: API_GATEWAY_URL + "internal/change_offer_image_path",
    validateCoregOfferPostCall: API_GATEWAY_URL + "admin/validate_coreg_offer_post_call",
    GetPartialSetting: API_GATEWAY_URL + "admin/get_partial_setting_details",
    UpdatePartialSetting: API_GATEWAY_URL + "admin/partial_setting_update",
    ListAllHiddenOffers: API_GATEWAY_URL + "admin/offers/all_trash_list",
    deletedOfferUndo: API_GATEWAY_URL + "admin/offers/revert_offer",
    GetThankyouUrls: API_GATEWAY_URL + "admin/lp/get_thankyou_urls",
    GetThankyouUrlsCounter: API_GATEWAY_URL + "admin/lp/get_thankyou_urls_counter",
    getThankYouUrlsReport: API_GATEWAY_URL + "admin/lp/get_thankyou_urls_report",

    // Master section API'S endpoints
    FetchMD5Rules: AuthUrl + "master/md5/fetched_md5_rules",
    FetchAllChildSystems: AuthUrl + "master/common/fetch_all_child_system",
    GenarateApiKey: AuthUrl + "master/offer/generate_api_key",
    ValidateOfferName: AuthUrl + "master/offer/check_offer_name_availability",
    CreateMasterOffer: AuthUrl + "master/offer/create_offer",
    ListAllOffers: AuthUrl + "master/offers/list_all_offers",
    GetOfferDetails: AuthUrl + "master/offers/get_offer_details",
    OfferNameAvailability: AuthUrl + "master/offer/validate_offer_name",
    GetOfferDetailsFromChildToParent: AuthUrl + "master/offers/get_offers_details_from_child_and_parent",
    UpdateOfferDetails: AuthUrl + "master/offer/edit_offer",
    GetMD5RuleDetails: AuthUrl + "master/md5/get_md5_rule_deatails",
    CreateMD5Rule: AuthUrl + "master/md5/create_md5_rule",
    UpdateMD5Rule: AuthUrl + "master/md5/edit_md5_rule",
    GetOfferStatus: AuthUrl + "master/offers/get_offer_status",
    saveReportFields: AuthUrl + "master/offers/upsert_report_fields",
    GetReportFields: AuthUrl + "master/offers/get_report_fields_details",
    HideMD5Rule: AuthUrl + "master/md5/update_md5_rule_status",
    FetchMD5RulesDropdown: AuthUrl + "master/md5/dropdown/fetched_md5_rules",
    FetchHiddenMD5Rules: AuthUrl + "master/md5/listAll_deleted_md5_rules",
    UnhideMD5Rule: AuthUrl + "master/md5/unhide_md5_rules",
    HideMasterOffer: AuthUrl + "master/offer/hide",
    ListAllMasterHiddenOffers: AuthUrl + "master/offers/list_all_hidden_offers",
    RestoreMasterOffer: AuthUrl + "master/offer/restore",

    // Collective Reports
    CreateReportGroup: AuthUrl + "master/report/create_report_group",
    FetchReportGroups: AuthUrl + "master/report/list_all_report_group",
    FetchGroupDetail: AuthUrl + "master/report/get_report_group_details",
    UpdateReportGroup: AuthUrl + "master/report/update_report_group",
    FetchCollectiveReport: AuthUrl + "master/report/get_collective_dashboard_data",
    FetchCollectiveBackDatedReport: AuthUrl + "master/report/get_collective_monthly_report",
    UpdateSitesRank: AuthUrl + "master/report/update_systems_rank",
    DownloadCollectiveReport: AuthUrl + "master/report/download_collective_report",
    DownloadMonthlyCollectiveReport: AuthUrl + "master/report/download_monthly_collective_report",
    DeleteReportGroup: AuthUrl + "master/report/delete_report_group",

    // Ongage Report
    FetchOngageReport: OngageReportUrl + "report/six_month_report",

    // Unsusbcribe Management
    CreateOngageList: OngageReportUrl + "unsubscribe/add_ongage_list",
    FetchOngageLists: OngageReportUrl + "unsubscribe/get_ongage_lists",
    UpdateOngageList: OngageReportUrl + "unsubscribe/update_ongage_list",
    DeleteOngageList: OngageReportUrl + "unsubscribe/delete_ongage_list",
    CreateGHLAcount: OngageReportUrl + "unsubscribe/add_ghl_account",
    FetchGHLAcounts: OngageReportUrl + "unsubscribe/get_ghl_accounts",
    CreatePTAcount: OngageReportUrl + "unsubscribe/add_pinktel_account",
    FetchPTAcounts: OngageReportUrl + "unsubscribe/get_pinktel_accounts",
    UpdatePTAccount: OngageReportUrl + "unsubscribe/update_pinktel_acc",
    DeletePTAccount: OngageReportUrl + "unsubscribe/delete_pinktel_acc",
    UpdateGHLAccount: OngageReportUrl + "unsubscribe/update_ghl_account",
    DeleteGHLAccount: OngageReportUrl + "unsubscribe/delete_ghl_account",
    CreateScrubEmail: OngageReportUrl + "unsubscribe/add_email_preference",
    FetchEmails: OngageReportUrl + "unsubscribe/list_all_email_preference",
    FetchEmailDetails: OngageReportUrl + "unsubscribe/get_email_preferences_details",
    UpdateScrubEmail: OngageReportUrl + "unsubscribe/update_email_preferences",
    FetchOpsHistory: OngageReportUrl + "unsubscribe/get_cron_item_details",
    AcknowledgeError: OngageReportUrl + "unsubscribe/acknowledge_error",
    CreateScrubPhone: OngageReportUrl + "unsubscribe/add_number_preference",
    FetchPhone: OngageReportUrl + "unsubscribe/list_all_number_preference",
    FetchPhoneDetails: OngageReportUrl + "unsubscribe/get_number_preference",
    UpdateScrubPhone: OngageReportUrl + "unsubscribe/update_number_preference",
    SearchEmails: OngageReportUrl + "unsubscribe/search_email_preference",
   
    FetchPhoneHistory: OngageReportUrl + "unsubscribe/get_cron_number_item_details",

};