import React  from "react";
import Span from '@material-ui/core/Box';
import { Typography, Divider } from '@material-ui/core';

const Footer = () => {
    const Year = new Date().getFullYear();

    return(
        <React.Fragment>
            <Span px={4} mt={3}>
               <Span><Divider/></Span>
                <Span my={4.5} align="right">
                <Typography variant="subtitle1">
                    Copyright {Year}. All Rights Reserved.
                </Typography>
                </Span>
            </Span>
        </React.Fragment>
    )
};
export default Footer;