import React from 'react';
import { Typography, AppBar, Toolbar, IconButton, withStyles, Menu, MenuItem } from '@material-ui/core';
import Span from "@material-ui/core/Box";
// import Logo from "../../assets/images/logo.png";
import { UserLogout } from '../../helper/commonFunction';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';

const styles = (theme) => ({
    Logo: { width: 97, paddingLeft: 110 },
    LogoCss: { textDecoration: "none", color: "#2B2B2B", fontWeight: 500, fontStyle: "italic" },
    LogoStyle: { color: "#498bf6", fontWeight: 500, },
    logoText: { fontSize: 25 },
    ToolBarHeight: { minHeight: 52 },
    AppbarCss: { boxShadow: 'none', background: '#fff', height: 62, borderTop: '3px solid #498bf6', borderBottom: '1px solid #dadada' },
    HeaderItems: { color: '#3d3d3d', fontWeight: 500, fontSize:13},
    AccDropdownIcon: { padding: 0, color: '#3d3d3d', marginTop: -2, marginRight: 110 },
    ProfileDropdown:{marginTop:30},
    supportLink:{textDecoration:'none', color:'#3d3d3d'},
    [theme.breakpoints.only('xs')]: {
        Logo: { width: 97, paddingLeft: '0px !important' },
        AccDropdownIcon: { marginRight: '0px !important' },
    },
    [theme.breakpoints.only('sm')]: {
        Logo: { paddingLeft: '0px !important' },
        AccDropdownIcon: { marginRight: '0px !important' },
    }

});

const Header = (props) => {
    const { classes, prps} = props;
   
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    

    return (
        <AppBar position="static" classes={{ root: classes.AppbarCss }}>
            <Toolbar className={classes.ToolBarHeight}>
                <Span flexGrow={1} mt={1}>
                        <a href="#/" className={classes.LogoCss}><Typography variant="subtitle2" className={classes.logoText}><span className={classes.LogoStyle}>Coreg Master Dashboard</span></Typography></a>
                    </Span>
                <Typography variant="caption" className={classes.HeaderItems}>Account
                    <IconButton 
                        classes={{ root: classes.AccDropdownIcon }}
                        onClick={handleMenu}
                    >
                        {(anchorEl) ? <ArrowDropUpOutlinedIcon/>: <ArrowDropDownOutlinedIcon/> }
                    </IconButton>
                </Typography>
                <div>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                        classes={{paper:classes.ProfileDropdown}}
                    >
                        <MenuItem onClick={()=>{UserLogout(prps)}}>
                            <Typography variant="caption">Logout</Typography>
                        </MenuItem>
                        {/* <MenuItem onClick={handleClose}>
                            <Typography variant="caption">
                                <a 
                                    className={classes.supportLink} 
                                    href="mailto:support@betwext.com" 
                                    rel="noreferrer"
                                    target="_blank">
                                    Support
                                </a>
                            </Typography>
                        
                        </MenuItem> */}
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
}

export default withStyles(styles)(Header);