import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import Navbar from './Navbar';
import Sidebar from './SideBar';
import Span from '@material-ui/core/Box';
import Snackbar from '../../components/Snackbar/Snackbar';
import { getSessionToken } from "../../utils/authentication/jwtUtils.js";
import { ValidateSessionTokenAPI } from '../../helper/services/API/Users';
import Footer from '../../components/Footer/Footer';
import { setCookieStorage } from '../../utils/CookieLocalStorage';
import { UserAccountContext } from '../../contextHooks';
import Loader from '../../helper/loaders/ComponentLoader';

const styles = (theme) => ({
    BannerContainer: { background: '#333', padding: '6px 10px', borderLeft: '4px solid #ffa500', borderRadius: 4, marginLeft: 29, marginRight: 29, marginBottom: 12 },
    BannerText: { color: '#fff', fontSize: 13, fontWeight: 500 },
    mainContent: {
        marginRight: 32,
        marginLeft: 100,
        marginTop: 80
    },
    [theme.breakpoints.only('xs')]: {
        mainContent: {
            marginRight: 12,
            marginLeft: 12,
            marginTop: 80
        }
    },
});

const DashboardLayout = (Props) => {
    const { classes, children } = Props;
    const childrenProps = children.props;
    const [userAccount, setUserAccount] = useState({});
    const [snackbarState, setSnackbarState] = useState({
        messageInfo: {
            open: false,
            message: null,
            variant: 'success'
        }
    });
    const setUserDataContext = (res) => {
        if (res.success && res.data) {
            let defaultSiteData = localStorage.getItem("site_details");
            let defaultSiteParsedata = JSON.parse(defaultSiteData);

            let siteData = localStorage.getItem("default_site_details");
            let defaultSite = JSON.parse(siteData);

            setUserAccount({ ...res.data, "site_details": defaultSiteParsedata, "default_site_details": defaultSite });
        } else if (res.message_code === 10001) {
            setSnackbarState({
                messageInfo: {
                    open: true,
                    message: res.message,
                    variant: 'error'
                }
            });
            let deleteSessionTokenCookie = setCookieStorage('cmd_session_token', null, { path: '/', sameSite: true });
            if (deleteSessionTokenCookie && childrenProps.history) {
                window.location.assign('/auth/login?sessexpire=true');
                localStorage.removeItem("id");
                localStorage.removeItem("default_site_details");
                localStorage.removeItem("phone");
                localStorage.removeItem("site_details");
                localStorage.removeItem("selected_site_details");
                localStorage.removeItem("Child_systems");
            }
        }
        else {
            setSnackbarState({
                messageInfo: {
                    open: true,
                    message: res.message,
                    variant: 'error'
                }
            });
        }
    };

    const routeAuthentication = (res) => {
        let { location, history } = Props.children.props;
        if (res.data && res.data) {
            let { role } = res.data;
            if(role === 'DASHBOARD' || role === 'DASHBOARD_LP'){
                if(!location.pathname.split('/').includes('dashboard')){
                    setSnackbarState({
                        messageInfo: { open: true, message: 'You do not have the necessary permissions to view this page '+location.pathname, variant: 'error' }
                    });
                    history.push('/admin/dashboard');
                }
            }
            else if(role === 'COLLECTIVE_DASHBOARD'){
                if(!location.pathname.split('/').includes('collective-dashboard')){
                    setSnackbarState({
                        messageInfo: { open: true, message: 'You do not have the necessary permissions to view this page '+location.pathname, variant: 'error' }
                    });
                    history.push('/admin/collective-dashboard');
                }
            }
        }
    }

    useEffect(() => {
        (async () => {
            let tokenInfo = await validateToken();
            routeAuthentication(tokenInfo);
            setUserDataContext(tokenInfo);
            if (!getSessionToken()) {
                childrenProps.history.push('/auth/login');
            }
        })().catch(err => {
            console.error('Caught error :', err);
            setSnackbarState({
                messageInfo: {
                    open: true,
                    message: 'Something went wrong!',
                    variant: 'error'
                }
            });
        });

        //The "react-hooks/exhaustive-deps" rule warns us when we have a missing dependency in an effect hook. To get rid of the warning, move the function or variable declaration inside of the useEffect hook, memoize arrays and objects that change on every render or disable the rule.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [childrenProps]);

    const validateToken = async () => {
        try {
            let tokenState = await ValidateSessionTokenAPI();
            return tokenState;
        } catch (error) {
            throw error;
        };
    };

    return (
        <React.Fragment>
            {snackbarState.messageInfo.open && <Snackbar
                message={snackbarState.messageInfo.message}
                open={snackbarState.messageInfo.open}
                closeSnackBar={() => {
                    setSnackbarState({ messageInfo: { open: false, message: null, variant: 'success' } });
                }}
                variant={snackbarState.messageInfo.variant}
                autoHideDuration={10000}
            />}
            {
                Object.keys(userAccount).length > 0 ?
                    <React.Fragment>
                        <UserAccountContext.Provider value={userAccount}>
                            <Navbar />
                            <Sidebar />
                            <Span className={classes.mainContent}>
                                {children}
                                <Footer />
                            </Span>
                        </UserAccountContext.Provider>
                    </React.Fragment>
                    : <Loader />
            }
        </React.Fragment>
    );
}

export default withStyles(styles)(DashboardLayout);
