import React from "react";
import { Switch } from 'react-router-dom';
import AppRoute from '../AppRoute';
import Loader from '../../helper/loaders';
import { dashboardLayout } from "../../layouts/DashboardLayout";

const AllLps = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Lp/AllLps")
)

const CreateLP = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Lp/CreateLP")
);

const LPOffers = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Offers/LPOffers")
);

const EditLp = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Lp/EditLp")
);

const UpdateAllLp = Loader(() =>
    import( /* webpackChunkName: "NotFoundPage" */ "../../containers/Lp/UpdateAllLp")
);

const LpComponent = (props) => {
    let { match } = props;
    return (
        <React.Fragment>
            <Switch>
                <AppRoute
                    path={`${match.path}/all-lp`}
                    component={AllLps}
                    layout={dashboardLayout}
                    type='public'
                    title='All Lp'
                />

                <AppRoute
                    path={`${match.path}/create-lp`}
                    component={CreateLP}
                    layout={dashboardLayout}
                    type='public'
                    title='All Lp'
                />

                <AppRoute
                    path={`${match.path}/lp-offers`}
                    component={LPOffers}
                    // requireAuth={auth}
                    layout={dashboardLayout}
                    type='public'
                    title='LP Offers'
                />

                <AppRoute
                    path={`${match.path}/edit/:id`}
                    component={EditLp}
                    layout={dashboardLayout}
                    type='public'
                    title='Fields'
                />

                <AppRoute
                    path={`${match.path}/update-all-lp`}
                    component={UpdateAllLp}
                    layout={dashboardLayout}
                    type='public'
                    title='Update All Lp'
                />
            </Switch>
        </React.Fragment>
    )
}

export default LpComponent;