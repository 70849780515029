import React from 'react'
import { Drawer, withStyles, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTags, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const styles = (theme) => ({
    drawerPaper: { marginTop: 62, background: '#f5f1f1', width: 262, boxShadow: 'none', borderRight: 'none', marginLeft: 70 },
    ListItemPadding: { paddingLeft: 17, paddingRight: 13 },
    ListAvatar: { background: '#498bf6', color: '#fff', fontSize: 14, width: 30, height: 30 },
    ListIconContainer: { minWidth: 36 },
    ListTitle: { fontSize: 14 },
    DrawerModel: { position: 'relative !important', zIndex: '1200 !important' },
    [theme.breakpoints.only('xs')]: {
        DrawerModel: { position: 'relative !important', zIndex: '1300 !important' },
    },
})

const MasterMD5RulesSubmenu = (props) => {
    const { classes, openState, closeSubNavigation } = props;
    return (
        <React.Fragment>
            <Drawer
                variant="temporary"
                anchor="left"
                open={openState}
                onClose={closeSubNavigation}
                classes={{ paperAnchorLeft: classes.drawerPaper, modal: classes.DrawerModel }}
                BackdropProps={{ style: { top: '62px', left: 70 } }}
            >
                <List component="nav" disablePadding>
                    <ListItem
                        button
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/master/add-md5Rule"
                        onClick={() => { closeSubNavigation() }}
                    >
                        <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                            <Avatar classes={{ root: classes.ListAvatar }}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.ListTitle }} primary="Add Rule" />
                    </ListItem>

                    <ListItem
                        button
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/master/manage-md5Rules"
                        onClick={() => { closeSubNavigation() }}
                    >
                        <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                            <Avatar classes={{ root: classes.ListAvatar }}>
                                <FontAwesomeIcon icon={faTags} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.ListTitle }} primary="All MD5 Rules" />
                    </ListItem>

                    <ListItem
                        button
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/master/manage-hidden-md5Rules"
                        onClick={() => { closeSubNavigation() }}
                    >
                        <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                            <Avatar classes={{ root: classes.ListAvatar }}>
                                <FontAwesomeIcon icon={faEyeSlash} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.ListTitle }} primary="Hidden MD5 Rules" />
                    </ListItem>
                </List>
            </Drawer>
        </React.Fragment>
    )
}

export default withStyles(styles)(MasterMD5RulesSubmenu);
