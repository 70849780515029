import React,{ useEffect } from 'react';

const WelcomePage = (props) => {
    const { history } = props;
    useEffect(() => {
        history.push('/auth/login');
    }, [history]);
    return(
        <h2 style={{textAlign:'center', color:'#498bf6'}}>Welcome, Coreg Master</h2>
    )
};

export default WelcomePage;