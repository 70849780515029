import React from 'react';
import AppRoute from './AppRoute';
import Loader from '../helper/loaders';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { errorLayout } from '../layouts/ErrorLayout';
import PublicLayout from '../layouts/PublicLayout/PublicLayout';
import { Route, withRouter } from 'react-router';
import WelcomePage from '../components/Welcome';
import AuthRoutes from '../routes/NestedRoutes/AuthRoutes';
import LpRoutes from '../routes/NestedRoutes/LpRoutes';
import FieldsRoute from '../routes/NestedRoutes/FieldsRoute';
import MasterOffersRoute from '../routes/NestedRoutes/MasterOffersRoute';
import UnsubscribersRoute from '../routes/NestedRoutes/Unsubscribers';

const NotFoundPage = Loader(() =>
    import(/* webpackChunkName: "NotFoundPage" */ '../containers/not-found-page.component')
);

const Routers = () => {

    return (
        <Router>
            <Switch>

                <AppRoute
                    exact={true}
                    path='/'
                    component={WelcomePage}
                    layout={PublicLayout}
                    type='public'
                    title='Welcome'
                />

                <Route
                    path='/auth'
                    component={AuthRoutes}
                />

                <Route
                    path='/admin'
                    component={FieldsRoute}
                />

                <Route
                    path='/admin/lp'
                    component={LpRoutes}
                />

                <Route
                    path='/master'
                    component={MasterOffersRoute}
                />

                <Route 
                    path='/unsubscribe'
                    component={UnsubscribersRoute}
                />

                <AppRoute
                    exact
                    path='*'
                    component={NotFoundPage}
                    requireAuth={() => false}
                    layout={errorLayout}
                    type='public'
                />

            </Switch>
        </Router>
    )
};

export default withRouter(Routers);