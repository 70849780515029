import { getCookieStorage } from '../CookieLocalStorage';

const STORE_NAME = 'cmd_session_token';

/* get session token from cookie storage */
export const getSessionToken = () => {

  let dataStorage = getCookieStorage(STORE_NAME);

  if (dataStorage && dataStorage) {
    return dataStorage;
  }
  return;
}

const jwtUtils = {
  getSessionToken
}

export default jwtUtils;