import { setCookieStorage } from '../utils/CookieLocalStorage';
import {UsersLogoutAPI} from './services/API/Users';

const refreshPage = () => {
    window.location.reload(false);
};

export const UserLogout = async(props) => {
    await UsersLogoutAPI().then((res) => {
        if(res.success && res.message_code === 8009){
            localStorage.removeItem("id");
            localStorage.removeItem("default_site_details");
            localStorage.removeItem("phone");
            localStorage.removeItem("site_details");
            localStorage.removeItem("selected_site_details");
            localStorage.removeItem("Child_systems");
            let deleteSessionTokenCookie = setCookieStorage('cmd_session_token', null, { path: '/', sameSite: true });
            if (deleteSessionTokenCookie && props.history) {
                return props.history.push('/auth/login');
    }
        }
        else if (!props.success && props.message_code === 10001) {
            // message_code 10001 determined the session token is unauthorized or it expired
            window.location.assign('/auth/login?sessexpire=true');
            localStorage.removeItem("id");
            localStorage.removeItem("default_site_details");
            localStorage.removeItem("phone");
            localStorage.removeItem("site_details");
            localStorage.removeItem("selected_site_details");
            localStorage.removeItem("Child_systems");
            refreshPage();
        }
    })    
}

export const getUnixTimeStamp = () => {
    return Math.floor(new Date().getTime() / 1000);
};

export const getMonthString = (priorMonths) =>{
  	const date = new Date();
  	// accessing current month of the date
    const currentMonth = date.getMonth();

  	// subtracting required number of months
  	date.setMonth(currentMonth - priorMonths);
    
    const fullMonth = date.getMonth(); 
    const fullYear = date.getFullYear();
      
    const displayLastDay = new Date(fullYear, fullMonth +1, 0).getDate();
    const displayMonth = date.toLocaleString('default', { month: 'long' });

    return `Avg send size as of ${displayMonth} ${displayLastDay} ${fullYear}`;
}

