import React, { useState, useContext } from 'react'
import { withStyles, List, ListItem, ListItemText, ListItemIcon, } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faFileAlt, faShieldAlt, faTag, faRecordVinyl, faSitemap, faPersonArrowDownToLine, } from '@fortawesome/free-solid-svg-icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import OffersSubmenu from './OfferSubmenu';
import LeadsSubmenu from './LeadsSubmenu';
import UsersSubmenu from './UsersSubmenu';
import LpSubmenu from './LpSubmenu';
import FieldsSubmenu from './FieldsSubmenu';
import ReportSubmenu from './ReportSubmenu';
import MasterOffersSubmenu from './MasterOffersSubmenu';
import MasterMD5RulesSubmenu from './MasterMD5RulesSubmenu';
import UnsubscribeSubMenu from './UnsubscribeSubMenu';

import { NavLink } from 'react-router-dom';
import Snackbar from '../../components/Snackbar/Snackbar';
import { UserAccountContext } from '../../contextHooks';

const styles = () => ({
    ListItemCss: { display: 'block', textAlign: 'center', paddingTop: 10, paddingBottom: 3 },
    ListItemCssActive: { display: 'block', textAlign: 'center', paddingTop: 10, paddingBottom: 3, background: '#fff', borderLeft: '3px solid #498bf6', '&:hover': { background: '#fff' } },
    ListContainer: { textAlign: 'center' },
    ListItemIconCss: { minWidth: 0, fontSize: 30, color: '#fff' },
    ListItemTextCss: { color: '#fff', fontSize: 11 },
    ListItemTextToolsCss: { color: '#fff', fontSize: 11, marginTop: 6 },
    ListItemIconCssActive: { color: '#498bf6', minWidth: 0, fontSize: 30 },
    ListItemTextCssActive: { color: '#498bf6', fontSize: 11 },
    ListItemCssClicked: { display: 'block', textAlign: 'center', paddingTop: 10, paddingBottom: 3, background: '#fff', borderLeft: '3px solid #498bf6', '&:hover': { background: '#f5f1f1' } },
    ListItemTextCssIM: { color: '#fff', fontSize: 11, margin: '0px 2px' },
    IconCss: { fontSize: 30 },

})

const SideNavigationMenu = (props) => {
    const siteId = localStorage.getItem("id");

    const { classes, pageUrl } = props;
    const [snackbarState, setSnackbarState] = useState({
        messageInfo: {
            open: false,
            message: null,
            variant: 'success'
        }
    });
    const userAccount = useContext(UserAccountContext);

    const offersItem = ['create', 'manage-offers', 'edit-offer', 'lp-offers', "upload-MD5", 'hidden-offers'];
    const lpItems = ['create-lp', 'all-lp', 'edit', 'update-all-lp', 'thank-you-count'];
    const leadsItem = ['leads', 'invalid-leads', 'md5-denied-leads', 'dnc-denied-leads', 'cobrand-denied-leads'];
    const usersItem = ['create-user', 'create-group', 'edit-user', 'manage-users', 'deactivate'];
    const fieldsItem = ['fields', 'edit-fields', 'create-field', 'all-field'];

    const pageSplit = pageUrl.split('/');
    const rootItem = pageSplit[2];
    let isDahboardPage = pageSplit[2];
    let isLeadsPage = pageSplit[1];
    let usersPage = pageSplit[1];
    let lpPage = pageSplit[2]
    let settingpage = pageSplit[2];
    let profilePage = pageSplit[2];
    let lpOffersPage = pageSplit[1];
    let fieldsPage = pageSplit[1];

    let offersPageIndex = offersItem.indexOf(pageSplit[3]);
    let leadsPageIndex = leadsItem.indexOf(pageSplit[2]);
    let usersPageIndex = usersItem.indexOf(pageSplit[3]);
    let fieldsPageIndex = fieldsItem.indexOf(pageSplit[2]);
    let lpOffersPageIndex = lpItems.indexOf(pageSplit[3]);

    const [subNavSelected, setSubNavSelected] = useState(null);
    const [clickedItem, setClickedItem] = useState(null);
    const openSubNavigation = (item) => {
        setClickedItem(item);
        setSubNavSelected(item);
    }
    const closeSubNavigation = () => {
        setClickedItem(null);
        setSubNavSelected(null);
    }

    const masterOffersItem = ['create-offer', 'all-offers', 'edit-offer', 'hidden-offers'];
    const masterMd5Items = ['add-md5Rule', 'manage-md5Rules', 'edit-md5Rule', 'manage-hidden-md5Rules'];
    const masterRepItems = ['reports', 'create-report-group', 'manage-report-groups', 'edit-report-group', 'manage-site-order', 'ongage-report'];
    const masterUnsubItems = ['manage-ongage', 'manage-ghl', 'manage-pinktel', 'add-email', 'manage-emails', 'edit-email','manage-phone','edit-phone'];

    const masterPageSplit = pageUrl.split('/');
    const masterRootItem = masterPageSplit[2];
    const masterLpPage = masterPageSplit[2];
    const masterMd5Page = masterPageSplit[1];
    const masterOffersPageIndex = masterOffersItem.indexOf(masterPageSplit[2]);
    const masterMd5PageIndex = masterMd5Items.indexOf(masterPageSplit[2]);
    const masterReportPageIndex = masterRepItems.indexOf(masterPageSplit[2]);
    const masterUnsubPageIndex = masterUnsubItems.indexOf(masterPageSplit[2]);
    const [masterSubNavSelected, setMasterSubNavSelected] = useState(null);
    const [masterClickedItem, setMasterClickedItem] = useState(null);

    const masterOpenSubNavigation = (item) => {
        setMasterSubNavSelected(item);
        setMasterClickedItem(item);
    }

    const masterCloseSubNavigation = () => {
        setMasterClickedItem(null);
        setMasterSubNavSelected(null);
    }


    return (
        <React.Fragment>
            <div className={classes.ListContainer}>
                <List component='div' disablePadding>
                    {(siteId !== '0' && userAccount.role !== "COLLECTIVE_DASHBOARD") &&
                        <ListItem
                            button
                            disableGutters
                            component={NavLink}
                            to="/admin/dashboard"
                            className={
                                (rootItem === 'dashboard' && isDahboardPage === 'dashboard')
                                    ? classes.ListItemCssActive
                                    : classes.ListItemCss
                            }
                        >
                            <ListItemIcon className={
                                (rootItem === 'dashboard' && isDahboardPage === 'dashboard')
                                    ? classes.ListItemIconCssActive
                                    : classes.ListItemIconCss
                            }
                            >
                                <DashboardIcon className={classes.IconCss} />
                            </ListItemIcon>
                            <ListItemText classes={
                                (rootItem === 'dashboard' && isDahboardPage === 'dashboard')
                                    ? { primary: classes.ListItemTextCssActive }
                                    : { primary: classes.ListItemTextCss }
                            }
                                primary='Dashboard'
                            />
                        </ListItem>
                    }
                    {(siteId !== '0' && userAccount.role === "COLLECTIVE_DASHBOARD") &&
                        <ListItem
                            button
                            disableGutters
                            component={NavLink}
                            to="/admin/collective-dashboard"
                            className={
                                (rootItem === 'collective-dashboard' && isDahboardPage === 'collective-dashboard')
                                    ? classes.ListItemCssActive
                                    : classes.ListItemCss
                            }
                        >
                            <ListItemIcon className={
                                (rootItem === 'collective-dashboard' && isDahboardPage === 'collective-dashboard')
                                    ? classes.ListItemIconCssActive
                                    : classes.ListItemIconCss
                            }
                            >
                                <DashboardIcon className={classes.IconCss} />
                            </ListItemIcon>
                            <ListItemText classes={
                                (rootItem === 'collective-dashboard' && isDahboardPage === 'collective-dashboard')
                                    ? { primary: classes.ListItemTextCssActive }
                                    : { primary: classes.ListItemTextCss }
                            }
                                primary='Collective Dashboard'
                            />
                        </ListItem>
                    }
                    {((siteId !== '0') && (userAccount.role !== "DASHBOARD" && userAccount.role !== "DASHBOARD_LP" && userAccount.role !== "COLLECTIVE_DASHBOARD")) &&
                        <ListItem
                            button disableGutters
                            className={
                                (clickedItem === 'offers') ? classes.ListItemCssClicked :
                                    ((rootItem === 'offers' || rootItem === lpPage) && offersPageIndex >= 0)
                                        ? classes.ListItemCssActive
                                        : classes.ListItemCss
                            }
                            onClick={() => { openSubNavigation('offers') }}
                        >
                            <ListItemIcon
                                className={
                                    (((rootItem === 'offers' || rootItem === lpPage) && offersPageIndex >= 0) || (clickedItem === 'offers'))
                                        ? classes.ListItemIconCssActive
                                        : classes.ListItemIconCss
                                }
                            >
                                <FontAwesomeIcon icon={faTag} />
                            </ListItemIcon>
                            <ListItemText
                                classes={
                                    (((rootItem === 'offers' || rootItem === lpPage) && offersPageIndex >= 0) || (clickedItem === 'offers'))
                                        ? { primary: classes.ListItemTextCssActive }
                                        : { primary: classes.ListItemTextCss }
                                }
                                primary='Offers'
                            />
                        </ListItem>}
                    <OffersSubmenu
                        openState={subNavSelected === 'offers' ? true : false}
                        closeSubNavigation={() => closeSubNavigation()}
                    />
                    {((siteId !== '0') && (userAccount.role !== "DASHBOARD" && userAccount.role !== "DASHBOARD_LP" && userAccount.role !== "COLLECTIVE_DASHBOARD")) &&
                        <ListItem
                            button disableGutters
                            className={
                                (clickedItem === 'lp') ? classes.ListItemCssClicked :
                                    ((rootItem === 'lp' || rootItem === lpOffersPage) && (lpOffersPageIndex >= 0))
                                        ? classes.ListItemCssActive
                                        : classes.ListItemCss
                            }
                            onClick={() => { openSubNavigation('lp') }}
                        >
                            <ListItemIcon
                                className={
                                    (((rootItem === 'lp' || rootItem === lpOffersPage) && (lpOffersPageIndex >= 0)) || (clickedItem === 'lp'))
                                        ? classes.ListItemIconCssActive
                                        : classes.ListItemIconCss
                                }
                            >
                                <FontAwesomeIcon icon={faRecordVinyl} />
                            </ListItemIcon>
                            <ListItemText
                                classes={
                                    (((rootItem === 'lp' || rootItem === lpOffersPage) && (lpOffersPageIndex >= 0)) || (clickedItem === 'lp'))
                                        ? { primary: classes.ListItemTextCssActive }
                                        : { primary: classes.ListItemTextCss }
                                }
                                primary='Lp'
                            />
                        </ListItem>}
                    <LpSubmenu
                        openState={subNavSelected === 'lp' ? true : false}
                        closeSubNavigation={() => closeSubNavigation()}
                    />
                    {((siteId !== '0') && (userAccount.role !== "DASHBOARD" && userAccount.role !== "DASHBOARD_LP" && userAccount.role !== "COLLECTIVE_DASHBOARD")) &&
                        <ListItem
                            button disableGutters
                            className={
                                (clickedItem === 'fields') ? classes.ListItemCssClicked :
                                    ((rootItem === 'fields' || rootItem === fieldsPage) && fieldsPageIndex >= 0)
                                        ? classes.ListItemCssActive
                                        : classes.ListItemCss
                            }
                            onClick={() => { openSubNavigation('fields') }}
                        >
                            <ListItemIcon
                                className={
                                    (((rootItem === 'fields' || rootItem === fieldsPage) && fieldsPageIndex >= 0) || (clickedItem === 'fields'))
                                        ? classes.ListItemIconCssActive
                                        : classes.ListItemIconCss
                                }
                            >
                                <FontAwesomeIcon icon={faFileAlt} />
                            </ListItemIcon>
                            <ListItemText
                                classes={
                                    (((rootItem === 'fields' || rootItem === fieldsPage) && fieldsPageIndex >= 0) || (clickedItem === 'fields'))
                                        ? { primary: classes.ListItemTextCssActive }
                                        : { primary: classes.ListItemTextCss }
                                }
                                primary='Fields'
                            />
                        </ListItem>}
                    <FieldsSubmenu
                        openState={subNavSelected === 'fields' ? true : false}
                        closeSubNavigation={() => closeSubNavigation()}
                    />
                    {(siteId !== '0') && (userAccount.role !== "DASHBOARD" && userAccount.role !== "DASHBOARD_LP" && userAccount.role !== "COLLECTIVE_DASHBOARD") &&
                        <ListItem
                            button
                            disableGutters
                            className={(clickedItem === 'admin') ? classes.ListItemCssClicked :
                                (isLeadsPage === 'admin' && leadsPageIndex >= 0) ?
                                    classes.ListItemCssActive : classes.ListItemCss
                            }
                            onClick={() => openSubNavigation('admin')}
                        >
                            <ListItemIcon
                                className={((isLeadsPage === 'admin' && leadsPageIndex >= 0) || clickedItem === 'admin' || clickedItem === 'admin')
                                    ? classes.ListItemIconCssActive : classes.ListItemIconCss}
                            >
                                <FontAwesomeIcon icon={faShieldAlt} />
                            </ListItemIcon>
                            <ListItemText classes={((isLeadsPage === 'admin' && leadsPageIndex >= 0) || clickedItem === 'admin') ?
                                { primary: classes.ListItemTextCssActive } : { primary: classes.ListItemTextCss }}
                                primary='Leads' />
                        </ListItem>}
                    <LeadsSubmenu
                        openState={subNavSelected === 'admin' ? true : false}
                        closeSubNavigation={() => closeSubNavigation()}
                    />
                    {(siteId !== '0') && (userAccount.role !== "DASHBOARD" && userAccount.role !== "DASHBOARD_LP" && userAccount.role !== "COLLECTIVE_DASHBOARD") &&
                        <ListItem
                            button
                            disableGutters
                            component={NavLink}
                            to="/admin/settings"
                            className={(clickedItem === 'settings') ? classes.ListItemCssClicked :
                                (settingpage === 'settings') ?
                                    classes.ListItemCssActive : classes.ListItemCss
                            }
                            onClick={() => openSubNavigation('settings')}
                        >
                            <ListItemIcon className={
                                (settingpage === 'settings')
                                    ? classes.ListItemIconCssActive
                                    : classes.ListItemIconCss
                            }>
                                <SettingsIcon className={classes.IconCss} />
                            </ListItemIcon>
                            <ListItemText
                                classes={
                                    (settingpage === 'settings')
                                        ? { primary: classes.ListItemTextCssActive }
                                        : { primary: classes.ListItemTextCss }
                                }
                                primary='Settings'
                            />
                        </ListItem>}
                    {(siteId === '0') &&
                        <ListItem
                            button
                            disableGutters
                            className={(clickedItem === 'auth') ? classes.ListItemCssClicked :
                                (usersPage === 'auth' && usersPageIndex >= 0) ?
                                    classes.ListItemCssActive : classes.ListItemCss
                            }
                            onClick={() => openSubNavigation('auth')}
                        >
                            <ListItemIcon className={((usersPage === 'auth' && usersPageIndex >= 0) || clickedItem === 'auth') ?
                                classes.ListItemIconCssActive : classes.ListItemIconCss}>
                                <FontAwesomeIcon icon={faUsers} />
                            </ListItemIcon>
                            <ListItemText
                                classes={((usersPage === 'auth' && usersPageIndex >= 0) || clickedItem === 'auth') ?
                                    { primary: classes.ListItemTextCssActive } : { primary: classes.ListItemTextCss }}
                                primary='Users'
                            />
                        </ListItem>}
                    <UsersSubmenu
                        openState={subNavSelected === 'auth' ? true : false}
                        closeSubNavigation={() => closeSubNavigation()}
                    />

                    {(siteId !== '0') && (userAccount.role !== "DASHBOARD" && userAccount.role !== "DASHBOARD_LP" && userAccount.role !== "COLLECTIVE_DASHBOARD") &&
                        <ListItem
                            button
                            disableGutters
                            component={NavLink}
                            to="/auth/profile"
                            className={(clickedItem === 'profile') ? classes.ListItemCssClicked :
                                (profilePage === 'profile') ?
                                    classes.ListItemCssActive : classes.ListItemCss
                            }
                            onClick={() => openSubNavigation('profile')}
                        >
                            <ListItemIcon className={
                                (profilePage === 'profile')
                                    ? classes.ListItemIconCssActive
                                    : classes.ListItemIconCss
                            }>
                                <AccountCircleIcon className={classes.IconCss} />
                            </ListItemIcon>
                            <ListItemText
                                classes={
                                    (profilePage === 'profile')
                                        ? { primary: classes.ListItemTextCssActive }
                                        : { primary: classes.ListItemTextCss }
                                }
                                primary='Profile'
                            />
                        </ListItem>}

                    {(siteId === '0') &&
                        <ListItem
                            button disableGutters
                            className={
                                (masterClickedItem === 'master') ? classes.ListItemCssClicked :
                                    ((masterRootItem === 'master' || masterRootItem === masterLpPage) && masterOffersPageIndex >= 0)
                                        ? classes.ListItemCssActive
                                        : classes.ListItemCss
                            }
                            onClick={() => { masterOpenSubNavigation('master') }}
                        >
                            <ListItemIcon
                                className={
                                    (((masterRootItem === 'master' || masterRootItem === masterLpPage) && masterOffersPageIndex >= 0) || (masterClickedItem === 'master'))
                                        ? classes.ListItemIconCssActive
                                        : classes.ListItemIconCss
                                }
                            >
                                <LocalOfferIcon className={classes.IconCss} />
                            </ListItemIcon>
                            <ListItemText
                                classes={
                                    (((masterRootItem === 'master' || masterRootItem === masterLpPage) && masterOffersPageIndex >= 0) || (masterClickedItem === 'master'))
                                        ? { primary: classes.ListItemTextCssActive }
                                        : { primary: classes.ListItemTextCss }
                                }
                                primary='Offers'
                            />
                        </ListItem>}
                    <MasterOffersSubmenu
                        openState={masterSubNavSelected === 'master' ? true : false}
                        masterCloseSubNavigation={() => masterCloseSubNavigation()}
                    />

                    {(siteId === '0') &&
                        <ListItem
                            button
                            disableGutters
                            className={(clickedItem === 'master') ? classes.ListItemCssClicked :
                                (masterMd5Page === 'master' && masterMd5PageIndex >= 0) ?
                                    classes.ListItemCssActive : classes.ListItemCss
                            }
                            onClick={() => openSubNavigation('master')}
                        >
                            <ListItemIcon className={((masterMd5Page === 'master' && masterMd5PageIndex >= 0) || clickedItem === 'master') ?
                                classes.ListItemIconCssActive : classes.ListItemIconCss}>
                                <CloudUploadIcon className={classes.IconCss} />
                            </ListItemIcon>
                            <ListItemText
                                classes={((masterMd5Page === 'master' && masterMd5PageIndex >= 0) || clickedItem === 'master') ?
                                    { primary: classes.ListItemTextCssActive } : { primary: classes.ListItemTextCss }}
                                primary='MD5 Rules'
                            />
                        </ListItem>}
                    <MasterMD5RulesSubmenu
                        openState={subNavSelected === 'master' ? true : false}
                        closeSubNavigation={() => closeSubNavigation()}
                    />

                    {(siteId === '0') &&
                        <ListItem
                            button
                            disableGutters
                            className={(clickedItem === 'collective') ? classes.ListItemCssClicked :
                                (masterMd5Page === 'master' && masterReportPageIndex >= 0) ?
                                    classes.ListItemCssActive : classes.ListItemCss
                            }
                            onClick={() => openSubNavigation('collective')}
                        >
                            <ListItemIcon className={((masterMd5Page === 'master' && masterReportPageIndex >= 0) || clickedItem === 'collective') ?
                                classes.ListItemIconCssActive : classes.ListItemIconCss}>
                                <FontAwesomeIcon icon={faSitemap} />
                            </ListItemIcon>
                            <ListItemText
                                classes={((masterMd5Page === 'master' && masterReportPageIndex >= 0) || clickedItem === 'collective') ?
                                    { primary: classes.ListItemTextCssActive } : { primary: classes.ListItemTextCss }}
                                primary='Collective Reports'
                            />
                        </ListItem>
                    }
                    <ReportSubmenu
                        openState={subNavSelected === 'collective' ? true : false}
                        closeSubNavigation={() => closeSubNavigation()}
                    />

                    {(siteId === '0') &&
                        <ListItem
                            button
                            disableGutters
                            className={(clickedItem === 'unsubscribe') ? classes.ListItemCssClicked :
                                (masterUnsubPageIndex >= 0) ? classes.ListItemCssActive : classes.ListItemCss
                            }
                            onClick={() => openSubNavigation('unsubscribe')}
                        >
                            <ListItemIcon className={((masterUnsubPageIndex >= 0) || clickedItem === 'unsubscribe') ?
                                classes.ListItemIconCssActive : classes.ListItemIconCss}>
                                <FontAwesomeIcon icon={faPersonArrowDownToLine} />
                            </ListItemIcon>
                            <ListItemText
                                classes={((masterUnsubPageIndex >= 0) || clickedItem === 'unsubscribe') ?
                                    { primary: classes.ListItemTextCssActive } : { primary: classes.ListItemTextCss }}
                                primary='Global Unsubscribe'
                            />
                        </ListItem>
                    }
                    <UnsubscribeSubMenu
                        openState={subNavSelected === 'unsubscribe' ? true : false}
                        closeSubNavigation={() => closeSubNavigation()}
                    />
                </List>
            </div>

            {snackbarState.messageInfo.open && <Snackbar
                message={snackbarState.messageInfo.message}
                open={snackbarState.messageInfo.open}
                closeSnackBar={() => {
                    setSnackbarState({
                        messageInfo: {
                            open: false,
                            message: null,
                            variant: 'success'
                        }
                    })
                }}
                variant={snackbarState.messageInfo.variant}
                autoHideDuration={5000}
            />}
        </React.Fragment>
    )
}

export default withStyles(styles)(SideNavigationMenu)
