import React, { useState, useEffect, useContext } from 'react'
import {
    Typography, withStyles, AppBar, Toolbar, IconButton, Popover, CircularProgress,
    Hidden, Drawer, Button, FormControl, TextField, MenuItem, Divider, ButtonGroup,
} from '@material-ui/core';
import Span from "@material-ui/core/Box";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SideNavigationsMenu from '../../components/SideNavigations/SideNavigationsMenu';
import { withRouter } from 'react-router-dom';
import { UserAccountContext } from '../../contextHooks';
// import { UserLogout } from '../../helper/commonFunction';
import { UsersLogoutAPI, GetPartialSettingAPI, UpdatePartialSettingAPI } from '../../helper/services/API/Users';
import { setCookieStorage } from '../../utils/CookieLocalStorage';
import CircularLoader from '../../helper/loaders/CircularLoader';
import Snackbar from '../../components/Snackbar/Snackbar';
import SettingsIcon from '@material-ui/icons/Settings';

const styles = (theme) => ({
    LogoCss: { textDecoration: "none", color: "#2B2B2B", fontWeight: 500, fontStyle: "italic" },
    LogoStyle: { color: "#498bf6", fontWeight: 500, textTransform: "capitalize" },
    logoText: { fontSize: 25 },
    AppbarCss: { boxShadow: 'none', background: '#fff', height: 62, borderTop: '3px solid #498bf6', borderBottom: '1px solid #dadada' },
    ToolBarHeight: { minHeight: 52 },
    HeaderItems: { color: '#3d3d3d', fontWeight: 500 },
    HeaderEmail: { color: '#3d3d3d', fontWeight: 500, marginRight: "20px" },
    HeaderIcons: { fontSize: 38 },
    HeaderItemText: { fontSize: 11, lineHeight: '0.60rem', color: '#000' },
    BadgeCss: { transform: 'initial', minWidth: 10, height: 10, marginRight: 4, marginTop: 10, background: '#498bf6', padding: '0px 0px' },
    ProfileDropdown: { marginTop: 18, width: 350, outline: 'none', padding: '12px 6px' },
    MenuButton: { padding: 0 },
    drawerPaper: { marginTop: 62, background: '#498bf6', boxShadow: 'none', width: 70, borderRight: 'none', },
    ListItemCss: { display: 'block', textAlign: 'center', paddingTop: 10, paddingBottom: 3 },
    ListContainer: { textAlign: 'center' },
    ListItemIconCss: { minWidth: 0, fontSize: 30, color: '#fff' },
    ListItemTextCss: { color: '#fff', fontSize: 11 },
    ListItemTextToolsCss: { color: '#fff', fontSize: 11, marginTop: 6 },
    HeaderIconIM: { fontSize: 26, marginTop: 4, marginBottom: 2 },
    AlertDropdown: { padding: '10px 10px', width: 210, top: '63px !important' },
    UserCircleIcon: { fontSize: 65, color: "#498bf6", },
    UserDetails: { fontSize: 15, marginTop: 3, wordBreak: 'break-all' },
    textFieldFolder: { fontSize: 13, color: '#2b2b2b', padding: '8px 10px', background: '#fff', '&::placeholder': { color: '#000', fontWeight: 500 } },
    cssLabel: { color: '#908f8f', transform: "translate(14px, 13px) scale(1)", fontSize: 13, },
    LableShrink: { fontSize: 15, transform: 'translate(17px, -4px) scale(0.75) !important', color: '#232323' },

    // ProfileDropdown: { marginTop: 18, width: 350, outline: 'none', padding: '12px 6px' },

    // UserCircleIcon: { fontSize: 65, },
    settingIconCssAct: { fontSize: 38, color: "green", marginTop: 5 },
    settingIconCssDis: { fontSize: 38, color: "red", marginTop: 5 },
    LogoutBtn: { color: "#498bf6", fontSize: 14, fontWeight: 500, },
    switchBtn: { boxShodow: "none", textTransform: "capitalize", color: "#498bf6", border: "1px solid #498bf6", padding: "4px 5px", fontSize: "12px" },
    switchBtnLong: { boxShodow: "none", textTransform: "capitalize", border: "1px solid #498bf6", padding: "4px 5px", fontSize: "12px", background: "#498bf6 !important", color: "#fff", },
    // RowTitles: { marginRight: "29px !important" },
    // RowTitles1: { marginRight: 19 },
    UserBtns: { fontSize: 12, border: '1px solid #498bf6', color: '#498bf6', margin: '0px 4px', padding: '5px 7px', '&:hover': { background: 'none' } },
    ItemContainer: { padding: 0, borderRadius: 0, background: 'transparent', margin: '0px 5px', color: '#2b2b2b', '&:hover': { background: 'transparent' } },
    contentSpacingCss: { marginLeft: "30px" },
    contentSpacingCss2: { marginLeft: "30px" },
    ButtonSpacingCss: { marginLeft: "58px" },
    kickboxTitleCss: { marginLeft: "25px" },
    contentSpacingCss3: { marginLeft: "55px" },
    settigLoaderCss: { marginTop: "20px" },
    UserBtnIcons: { fontSize: 21, marginLeft: 4 },
    IconCss: { fontSize: 30 },

    [theme.breakpoints.only('xs')]: {
        logoText: { fontSize: 13, fontWeight: 'bold', marginLeft: 12 },
        UserBtns: { fontSize: 11, border: '1px solid #498bf6', color: '#498bf6', margin: '0px 2px', padding: '5px 6px', '&:hover': { background: 'none' } },
        UserBtnIcons: { fontSize: 18, marginLeft: 4 },
        textFieldFolder: { fontSize: 12, color: '#2b2b2b', padding: '8px 8px', background: '#fff', '&::placeholder': { color: '#000', fontWeight: 500 } },
    },
})

const Navbar = (props) => {
    const { classes } = props;
    const userAccount = useContext(UserAccountContext);
    const selectedSiteID = localStorage.getItem("selected_site_details");

    //console.log(userAccount);
    const [defaultSiteArr, setDefaultSiteArr] = useState('select-site');
    const [allSites, setAllSites] = useState(null);
    const [siteName, setSiteName] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    const [usrId, setUsrId] = useState("");
    const [ipBlockerState, setIpBlockerState] = useState(false);
    const [kickboxStatusState, setKickboxStatusState] = useState(false);
    const [recaptchaState, setRecaptchaState] = useState(false);
    const [ipBlockerStateLoader, setIpBlockerStateLoader] = useState(false);
    const [kickboxStatusStateLoader, setKickboxStatusStateLoader] = useState(false);
    const [recaptchaStateLoader, setRecaptchaStateLoader] = useState(false);
    const [settingLoader, setSettingLoader] = useState(false);
    const [blockerState, setBlockerState] = useState(true);
    const [snackbarState, setSnackbarState] = useState({
        messageInfo: {
            open: false,
            message: null,
            variant: 'success'
        }
    });
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setSettingLoader(true);
        GetPartialSettingAPI().then((res) => {
            setSettingLoader(false);
            if (res && res.message_code === 4066) {
                setUsrId(res.data.id);
                let toggleBtn = res.data.ip_blocker;
                if (toggleBtn === "1") {
                    setIpBlockerState(false);
                } else {
                    setIpBlockerState(true);
                };

                let kickboxBtn = res.data.kickbox_status;
                if (kickboxBtn === "1") {
                    setKickboxStatusState(false);
                } else {
                    setKickboxStatusState(true);
                };

                let recaptchaBtn = res.data.recaptcha_enabled;
                if (recaptchaBtn === 1) {
                    setRecaptchaState(true);
                } else {
                    setRecaptchaState(false);
                };
            }
        });
    };

    const handleIpBlockerClick = () => {
        let newIpBlockerState = ipBlockerState === true ? false : true;
        let params = {
            id: usrId,
            ip_blocker: newIpBlockerState === false ? 1 : 0,
        }
        if (newIpBlockerState === false)
            setBlockerState(false);
        else if (kickboxStatusState === true)
            setBlockerState(true);

        setIpBlockerStateLoader(true);
        UpdatePartialSettingAPI(params).then((res) => {
            setIpBlockerStateLoader(false);
            if (res && res.success && res.message_code === 4065) {
                setIpBlockerState(newIpBlockerState)
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: res.message,
                        variant: "success"
                    }
                });
            } else if (res && !res.success) {
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: res.message,
                        variant: "error"
                    }
                });
            } else {
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: "Something went Wrong! Please try again later. ",
                        variant: "error",
                    },
                });
            }
        });
    };

    const handleKickboxStatusClick = () => {
        let newKickboxStatus = kickboxStatusState === true ? false : true;
        let params = {
            id: usrId,
            kickbox_status: newKickboxStatus === false ? 1 : 0,
        }
        setKickboxStatusStateLoader(true);
        if (newKickboxStatus === false)
            setBlockerState(false);
        else if (ipBlockerState === true)
            setBlockerState(true);

        UpdatePartialSettingAPI(params).then((res) => {
            setKickboxStatusStateLoader(false);
            if (res && res.success && res.message_code === 4065) {
                setKickboxStatusState(newKickboxStatus)
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: res.message,
                        variant: "success"
                    }
                });
            } else if (res && !res.success) {
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: res.message,
                        variant: "error"
                    }
                });
            } else {
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: "Something went Wrong! Please try again later. ",
                        variant: "error",
                    },
                });
            }
        });
        // setKickboxStatusState(newKickboxStatus)
    };

    const handleRecaptchaClick = () => {
        let newRecaptchaState = recaptchaState === true ? false : true;
        let params = {
            id: usrId,
            recaptcha_enabled: newRecaptchaState === false ? 0 : 1,
        }
        setRecaptchaStateLoader(true);
        UpdatePartialSettingAPI(params).then((res) => {
            setRecaptchaStateLoader(false);
            if (res && res.success && res.message_code === 4065) {
                setRecaptchaState(newRecaptchaState)
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: res.message,
                        variant: "success"
                    }
                });
            } else if (res && !res.success) {
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: res.message,
                        variant: "error"
                    }
                });
            } else {
                setSnackbarState({
                    messageInfo: {
                        open: true,
                        message: "Something went Wrong! Please try again later. ",
                        variant: "error",
                    },
                });
            }
        })
        // setRecaptchaState(newRecaptchaState)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };


    const refreshPage = () => {
        window.location.reload(false);
    };

    const onClickLogoutHandler = async () => {
        setBtnLoader(true);
        await UsersLogoutAPI().then((res) => {
            setBtnLoader(false);
            if (res.success && res.message_code === 8009) {
                localStorage.removeItem("id");
                localStorage.removeItem("default_site_details");
                localStorage.removeItem("phone");
                localStorage.removeItem("site_details");
                localStorage.removeItem("selected_site_details");
                localStorage.removeItem("Child_systems");
                localStorage.removeItem("crg_bloker");
                let deleteSessionTokenCookie = setCookieStorage('cmd_session_token', null, { path: '/', sameSite: true });
                if (deleteSessionTokenCookie && props.history) {
                    return props.history.push('/auth/login');
                }
            }
            else if (!props.success && props.message_code === 10001) {
                // message_code 10001 determined the session token is unauthorized or it expired
                window.location.assign('/auth/login?sessexpire=true');
                localStorage.removeItem("id");
                localStorage.removeItem("default_site_details");
                localStorage.removeItem("phone");
                localStorage.removeItem("site_details");
                localStorage.removeItem("selected_site_details");
                localStorage.removeItem("Child_systems");
                localStorage.removeItem("crg_bloker");
                refreshPage();
            }
        })
        // UserLogout(props)
    };

    const createOfferUrl = "/master/create-offer";
    const allOffersUrl = "/master/all-offers";
    const editOffersUrl = "masteredit-offer";
    const addMd5RuleUrl = "/master/add-md5Rule";
    const allMd5RulesUrl = "/master/manage-md5Rules";
    const editMd5RuleUrl = "masteredit-md5Rule";
    const manageUsersUrl = "/auth/master/manage-users";
    const createUserUrl = "/auth/master/create-user";
    const editUsersUrl = "authmasteredit-user";

    const locationAccess = () => {
        let tempData = window.location.pathname;
        const tempArr1 = tempData.split("/");
        const tempArr2 = tempArr1[0] + tempArr1[1] + tempArr1[2] + tempArr1[3];
        const tempArr3 = tempArr1[0] + tempArr1[1] + tempArr1[2];

        if (window.location.pathname === createOfferUrl) {
            return true;
        } else if (tempArr3 === editOffersUrl) {
            return true;
        } else if (window.location.pathname === allOffersUrl) {
            return true;
        } else if (window.location.pathname === addMd5RuleUrl) {
            return true;
        } else if (tempArr3 === editMd5RuleUrl) {
            return true;
        } else if (window.location.pathname === allMd5RulesUrl) {
            return true;
        } else if (window.location.pathname === manageUsersUrl) {
            return true;
        } else if (window.location.pathname === createUserUrl) {
            return true;
        } else if (tempArr2 === editUsersUrl) {
            return true;
        }
        else {
            return false;
        }
    };

    useEffect(() => {
        // GetPartialSettingAPI().then((res) => {
        //     setUsrId(res.data.id);
        //     let toggleBtn = res.data.ip_blocker;
        //     if (toggleBtn == "1") {
        //         setIpBlockerState(false);
        //     } else {
        //         setIpBlockerState(true);
        //     };

        //     let kickboxBtn = res.data.kickbox_status;
        //     if (kickboxBtn == "1") {
        //         setKickboxStatusState(false);
        //     } else {
        //         setKickboxStatusState(true);
        //     };

        //     let recaptchaBtn = res.data.recaptcha_enabled;
        //     if (recaptchaBtn === 1) {
        //         setRecaptchaState(true);
        //     } else {
        //         setRecaptchaState(false);
        //     };

        // });
        const blockerSetting = JSON.parse(localStorage.getItem("crg_bloker"));
        if (blockerSetting && (blockerSetting.ip_blocker === "1" || blockerSetting.kickbox_status === "1"))
            setBlockerState(false);

        var allSites = userAccount.site_details;
        if ((userAccount.role === "ADMIN") || (userAccount.role === "DASHBOARD") || (userAccount.role === "DASHBOARD_LP")) {
            setAllSites(allSites);
        } else {
            allSites = [...allSites, { child_system_id: 0, child_system_name: "Switch to master" }];
            setAllSites(allSites);
        }

        const selecetedSiteDetails = localStorage.getItem("selected_site_details");
        const tempId = localStorage.getItem("id");
        if ((userAccount.default_site_details !== null) && (selecetedSiteDetails)) {
            setDefaultSiteArr(selecetedSiteDetails);
        } else
            if (userAccount.default_site_details !== null) {
                let defaultSite = userAccount.default_site_details[0].site_id;
                setDefaultSiteArr(defaultSite);
            } else if (selecetedSiteDetails) {
                setDefaultSiteArr(selecetedSiteDetails);
            }
        if (selecetedSiteDetails === '0' && tempId !== 0) {
            localStorage.setItem("selected_site_details", tempId);
            // window.location.reload();
        }


        const siteId = JSON.parse(localStorage.getItem("id"));
        let customFieldsVal = [];
        allSites.forEach(e => {
            if ((e.child_system_id === siteId) && (e.child_system_name === "Switch to master")) {
                customFieldsVal.push("Coreg Master Dashboard");
            } else if ((e.child_system_id === siteId) && (e.child_system_name !== "Switch to master")) {
                customFieldsVal.push(e.child_system_name);
            }
        });
        setSiteName(customFieldsVal);
        // console.clear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSitesChange = (val) => {
        if (val !== "select-site") {
            if ((val !== 0) && (locationAccess() === false)) {
                localStorage.setItem("id", val);
                let sitesArr = [];
                allSites.forEach(element => {
                    if (element.child_system_id === val) {
                        sitesArr.push(element.child_system_id)
                    }
                });
                localStorage.setItem("selected_site_details", sitesArr);
                setDefaultSiteArr(val);
                // window.location.reload();
                window.location.href = "/admin/dashboard";
                //props.history.push("/admin/dashboard");

            } else if ((val !== 0) && (locationAccess() === true)) {
                localStorage.setItem("id", val);
                let sitesArr = [];
                allSites.forEach(element => {
                    if (element.child_system_id === val) {
                        sitesArr.push(element.child_system_id)
                    }
                });
                localStorage.setItem("selected_site_details", sitesArr);
                setDefaultSiteArr(val);
                //props.history.push("/admin/dashboard");
                window.location.href = "/admin/dashboard";
            }
            else if (val === 0) {
                localStorage.setItem("id", val);
                let sitesArr = [];
                allSites.forEach(element => {
                    if (element.child_system_id === val) {
                        sitesArr.push(element.child_system_id)
                    }
                });
                localStorage.setItem("selected_site_details", sitesArr);
                setDefaultSiteArr(val);
                //props.history.push("/auth/master/manage-users");
                window.location.href = "/master/all-offers";
            };
        }
    };
    return (
        <React.Fragment>
            <AppBar position='fixed' classes={{ root: classes.AppbarCss }}>
                <Toolbar className={classes.ToolBarHeight}>
                    <Hidden only={['sm', 'xl', 'md', 'lg']}>
                        <IconButton className={classes.MenuButton} disableRipple onClick={handleExpandClick} >
                            <Span align="center" display="grid" justifyItems='center'>
                                {(expanded) ? <CloseIcon classes={{ root: classes.HeaderIcons }} /> : <MenuIcon classes={{ root: classes.HeaderIcons }} />}
                            </Span>
                        </IconButton>
                        <Drawer
                            variant="temporary"
                            anchor="left"
                            open={expanded}
                            onClose={handleExpandClick}
                            classes={{ paperAnchorLeft: classes.drawerPaper }}
                            ModalProps={{
                                BackdropProps: { invisible: true }
                            }}
                        >
                            <SideNavigationsMenu
                                pageUrl={props.location.pathname}
                            />
                        </Drawer>
                    </Hidden>
                    <Span flexGrow={1} mt={1}>
                        <a href="#/" className={classes.LogoCss}><Typography variant="subtitle2" className={classes.logoText}><span className={classes.LogoStyle}> {(siteName && siteName.length > 0) ? siteName : 'Master Coreg'}</span></Typography></a>

                        {/* <a href="#/" className={classes.LogoCss}><Typography variant="subtitle2" className={classes.logoText}><span className={classes.LogoStyle}>Coreg Master Dashboard</span></Typography></a> */}
                    </Span>
                    {(userAccount.role !== 'COLLECTIVE_DASHBOARD') &&
                        <Span mt={1} mr={1}>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    variant="outlined"
                                    value={defaultSiteArr}
                                    onChange={(e) => handleSitesChange(e.target.value)}
                                    InputProps={{ classes: { input: classes.textFieldFolder, }, }}
                                    InputLabelProps={{ classes: { outlined: classes.cssLabel, shrink: classes.LableShrink } }}
                                >

                                    <MenuItem value="select-site">Select site</MenuItem>
                                    {allSites && allSites.map(option => (
                                        <MenuItem key={option.child_system_id} value={option.child_system_id}>
                                            {option.child_system_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Span>
                    }
                    {
                        ((userAccount.role === 'ADMIN' || userAccount.role === 'SUPER_ADMIN') && (selectedSiteID !== '0')) ?
                            <div>
                                <IconButton className={classes.ItemContainer} disableRipple aria-haspopup="true" onClick={handleMenu}>
                                    <SettingsIcon className={(blockerState) ? classes.settingIconCssAct : classes.settingIconCssDis} />
                                </IconButton>
                                <Popover
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    open={open}
                                    onClose={handleClose}
                                    classes={{ paper: classes.ProfileDropdown }}
                                >

                                    <Span align='center'>
                                        <SettingsIcon className={classes.UserCircleIcon} />
                                        <Span mt={2} mb={4}><Divider /></Span>
                                    </Span>
                                    {(settingLoader ?
                                        <Span py={1.7}>
                                            <CircularLoader size={22} color="primary" />
                                        </Span> :
                                        <Span>
                                            <Span display="flex" className={classes.contentSpacingCss}>
                                                <Typography variant='subtitle2' className={classes.kickboxTitleCss}>IP Blocker</Typography>
                                                <ButtonGroup disabled={(recaptchaStateLoader === true ? true : false) || (kickboxStatusStateLoader === true ? true : false)} className={classes.ButtonSpacingCss}>
                                                    <Button className={ipBlockerState === false ? classes.switchBtnLong : classes.switchBtn} onClick={handleIpBlockerClick}>OFF</Button>
                                                    <Button className={ipBlockerState === true ? classes.switchBtnLong : classes.switchBtn} onClick={handleIpBlockerClick}>ON</Button>
                                                </ButtonGroup>
                                                <Span className={classes.contentSpacingCss}>
                                                    {ipBlockerStateLoader === true && <CircularProgress color="primary" size={25} />}
                                                </Span>
                                            </Span>
                                            <Span my={2.7} display="flex" className={classes.contentSpacingCss2}>
                                                <Typography variant='subtitle2' className={classes.kickboxTitleCss}>Kickbox Status</Typography>

                                                <ButtonGroup disabled={(ipBlockerStateLoader === true ? true : false) || (recaptchaStateLoader === true ? true : false)} className={classes.contentSpacingCss2}>
                                                    <Button className={kickboxStatusState === false ? classes.switchBtnLong : classes.switchBtn} onClick={handleKickboxStatusClick}>OFF</Button>
                                                    <Button className={kickboxStatusState === true ? classes.switchBtnLong : classes.switchBtn} onClick={handleKickboxStatusClick}>ON</Button>
                                                </ButtonGroup>
                                                <Span className={classes.contentSpacingCss2}>
                                                    {kickboxStatusStateLoader === true && <CircularProgress color="primary" size={25} />}
                                                </Span>
                                            </Span>
                                            <Span my={2.7} display="flex" className={classes.contentSpacingCss2}>
                                                <Typography variant='subtitle2' className={classes.kickboxTitleCss}>Recaptcha</Typography>
                                                <ButtonGroup disabled={(recaptchaStateLoader === true ? true : false) || (kickboxStatusStateLoader === true ? true : false)} className={classes.contentSpacingCss3}>
                                                    <Button className={recaptchaState === false ? classes.switchBtnLong : classes.switchBtn} onClick={handleRecaptchaClick}>OFF</Button>
                                                    <Button className={recaptchaState === true ? classes.switchBtnLong : classes.switchBtn} onClick={handleRecaptchaClick}>ON</Button>
                                                </ButtonGroup>
                                                <Span className={classes.contentSpacingCss2}>
                                                    {recaptchaStateLoader === true && <CircularProgress color="primary" size={25} />}
                                                </Span>
                                            </Span>
                                        </Span>
                                    )}
                                    <Span mt={4}><Divider /></Span>
                                    <Span mt={2} align='center'>
                                        <Button
                                            size='small' className={classes.UserBtns}
                                            onClick={onClickLogoutHandler}
                                            disabled={btnLoader}
                                        >
                                            {btnLoader && <CircularLoader />}
                                            Logout
                                            <ExitToAppIcon className={classes.UserBtnIcons} />
                                        </Button>
                                    </Span>
                                </Popover>
                            </div>
                            :
                            <div>
                                <Span mt={1.2} align='right'>
                                    <Button
                                        size='small' className={classes.UserBtns}
                                        onClick={onClickLogoutHandler}
                                        disabled={btnLoader}
                                    >
                                        {btnLoader && <CircularLoader />}
                                        Logout
                                        <ExitToAppIcon className={classes.UserBtnIcons} />
                                    </Button>
                                </Span>
                            </div>
                    }
                </Toolbar>
            </AppBar>

            {snackbarState.messageInfo.open && <Snackbar
                message={snackbarState.messageInfo.message}
                open={snackbarState.messageInfo.open}
                closeSnackBar={() => {
                    setSnackbarState({
                        messageInfo: {
                            open: false,
                            message: null,
                            variant: 'success'
                        }
                    })
                }}
                variant={snackbarState.messageInfo.variant}
                autoHideDuration={5000}
            />}
        </React.Fragment>
    )
}


export default withStyles(styles)(withRouter(Navbar));