import React from 'react'
import { Drawer, withStyles, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie, faMicrophone, faMessage, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

const styles = (theme) => ({
    drawerPaper:{marginTop:62, background:'#f5f1f1', width:262, boxShadow:'none', borderRight:'none', marginLeft:70},
    ListItemPadding:{paddingLeft:17, paddingRight:13},
    ListAvatar:{background:'#498bf6', color:'#fff', fontSize:14, width:30, height:30},
    ListIconContainer:{minWidth:36},
    ListTitle:{fontSize:14},
    DrawerModel:{position:'relative !important', zIndex:'1200 !important'},
    [theme.breakpoints.only('xs')]: {
        DrawerModel:{position:'relative !important', zIndex:'1300 !important'},
    },
})

const UnsubSubmenu = (props) => {
    const {classes, openState, closeSubNavigation} = props;
    return (
        <React.Fragment>
            <Drawer
                variant="temporary"
                anchor="left"
                open={openState}
                onClose={closeSubNavigation}
                classes={{paperAnchorLeft:classes.drawerPaper, modal:classes.DrawerModel}}
                BackdropProps={{style : {top:'62px', left:70}}}
            >
                <ListItem
                    button
                    className={classes.SublistContainerPadding}
                    component={NavLink}
                    to="/unsubscribe/add-email"
                    onClick={() => { closeSubNavigation() }}
                >
                    <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                        <Avatar classes={{ root: classes.ListAvatar }}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </Avatar>
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.ListTitle }} primary="Add Email" />
                </ListItem>

                <ListItem
                    button
                    className={classes.SublistContainerPadding}
                    component={NavLink}
                    to="/unsubscribe/manage-emails"
                    onClick={() => { closeSubNavigation() }}
                >
                    <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                        <Avatar classes={{ root: classes.ListAvatar }}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </Avatar>
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.ListTitle }} primary="Manage Emails" />
                </ListItem>

                <ListItem
                    button
                    className={classes.SublistContainerPadding}
                    component={NavLink}
                    to="/unsubscribe/manage-phone"
                    onClick={() => { closeSubNavigation() }}
                >
                    <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                        <Avatar classes={{ root: classes.ListAvatar }}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </Avatar>
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.ListTitle }} primary="Manage Phone" />
                </ListItem>

                <List component="nav" disablePadding>
                    <ListItem
                        button
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/unsubscribe/manage-ongage"
                        onClick={() => { closeSubNavigation() }}
                    >
                        <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                            <Avatar classes={{ root: classes.ListAvatar }}>
                                <FontAwesomeIcon icon={faChartPie} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.ListTitle }} primary="Manage Ongage" />
                    </ListItem>

                    <ListItem
                        button
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/unsubscribe/manage-ghl"
                        onClick={() => { closeSubNavigation() }}
                    >
                        <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                            <Avatar classes={{ root: classes.ListAvatar }}>
                                <FontAwesomeIcon icon={faMicrophone} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.ListTitle }} primary="Manage GHL" />
                    </ListItem>

                    <ListItem
                        button
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/unsubscribe/manage-pinktel"
                        onClick={() => { closeSubNavigation() }}
                    >
                        <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                            <Avatar classes={{ root: classes.ListAvatar }}>
                                <FontAwesomeIcon icon={faMessage} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.ListTitle }} primary="Manage Pinktel" />
                    </ListItem>

                    {/*<ListItem
                        button
                        className={classes.SublistContainerPadding}
                        component={NavLink}
                        to="/master/ongage-report"
                        onClick={() => { closeSubNavigation() }}
                    >
                        <ListItemIcon classes={{ root: classes.ListIconContainer }}>
                            <Avatar classes={{ root: classes.ListAvatar }}>
                                <FontAwesomeIcon icon={faChartPie} />
                            </Avatar>
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.ListTitle }} primary="Ongage Report" />
                    </ListItem> */}
                </List>
                
            </Drawer>
        </React.Fragment>
    )
}

export default withStyles(styles)(UnsubSubmenu);
