import { API_ENDPOINTS_URLS } from '../apiendpointurls';
import { serviceRequest } from '../../apihelper';
import { getSessionToken } from '../../../utils/authentication/jwtUtils';

export const UserSigninAPI = (params) => {
    let url = API_ENDPOINTS_URLS.Login;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params)
    }

    return serviceRequest(url, requestOptions);
};

export const UsersLogoutAPI = () => {
    let url = API_ENDPOINTS_URLS.Logout;

    let requestOptions = {
        method: 'DELETE',
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const ForgotPasswordAPI = (params) => {
    let url = API_ENDPOINTS_URLS.ForgotPassword;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    };

    return serviceRequest(url, requestOptions);
};

export const ValidateForgotLinkAPI = (params) => {
    let url = API_ENDPOINTS_URLS.ValidateForgotLink;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const ResetPasswordAPI = (params) => {
    let url = API_ENDPOINTS_URLS.ResetPassword;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const ValidateSessionTokenAPI = (params) => {
    let url = API_ENDPOINTS_URLS.ValidateSessionToken;
    let requestOptions = {
        method: 'GET',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const TwoFactorLoginAPI = (params) => {
    let url = API_ENDPOINTS_URLS.TwoFactorLogin;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const VerifyTwoFactorLoginAPI = (params) => {
    let url = API_ENDPOINTS_URLS.VerifyTwoFactorLogin;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const FetchUserSiteSettingsAPI = (params) => {
    let url = API_ENDPOINTS_URLS.FetchUserSiteSettings;

    let requestOptions = {
        method: 'GET',
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const SetDefaultSiteAPI = (params) => {
    let url = API_ENDPOINTS_URLS.SetDefaultSite;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const GetDefaultSiteSettingAPI = () => {
    let url = API_ENDPOINTS_URLS.GetDefaultSiteSetting;

    let requestOptions = {
        method: 'GET',
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const GetAllSiteSettingDetailsAPI = () => {
    let url = API_ENDPOINTS_URLS.GetAllSiteSettingDetails;

    let requestOptions = {
        method: 'GET',
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const CreateUserAPI = (params) => {
    let url = API_ENDPOINTS_URLS.CreateUser;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const GetAllUsersAPI = () => {
    let url = API_ENDPOINTS_URLS.GetAllUsers;

    let requestOptions = {
        method: 'GET',
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const GetSingleUserDetailAPI = (params) => {
    let url = API_ENDPOINTS_URLS.GetSingleUserDetail;

    Object.entries(params).forEach(([key, val]) => {
        if (key === 'id')
            url += `?${key}=${val}`;
        else
            url += `&${key}=${val}`;
    });

    let requestOptions = {
        method: 'GET',
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const UpdateUserDetailsAPI = (params) => {
    let url = API_ENDPOINTS_URLS.UpdateUserDetails;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const DeleteUserAPI = (params) => {
    let url = API_ENDPOINTS_URLS.DeleteUser;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const ChangePasswordAPI = (params) => {
    let url = API_ENDPOINTS_URLS.ChangePassword;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};

export const GetPartialSettingAPI = () => {
    let url = API_ENDPOINTS_URLS.GetPartialSetting;

    let requestOptions = {
        method: 'GET',
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }

    return serviceRequest(url, requestOptions);
};

export const UpdatePartialSettingAPI = (params) => {
    let url = API_ENDPOINTS_URLS.UpdatePartialSetting;

    let requestOptions = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: {
            'session-token': getSessionToken('cmd_session_token')
        }
    }
    return serviceRequest(url, requestOptions);
};