import React from 'react';
import './App.css';
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core/styles';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from "history";
import Route from './routes/app.routes';

const theme = createTheme({
  palette: {
    primary: { main: '#498bf6' }, // Purple and green play nicely together.
    secondary: { main: '#498bf6' } // This is just pink.A700 as hex.
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor:'#498bf6',
      },
    },
    MuiPickersToolbarText:{
      toolbarTxt:{
        color:'#fff'
      },
      toolbarBtnSelected:{
        color:'#fff'
      }
    },
    MuiPickersDay:{
      daySelected:{
        color:'#fff'
      }
    },
	MuiPickersClockNumber:{
		clockNumberSelected:{
			color:'#fff'
		}
	}
  },
});

function App() {
  const history = createBrowserHistory();

  return (
    <MuiThemeProvider theme={theme}>
    <Router history={history}>
        <Route />
    </Router>
  </MuiThemeProvider>
  );
}

export default App;